import React from "react";

import { Grid } from "@mui/material";

import { Select, TextField } from "@aclymatepackages/atoms";

import FuelTypesSelect from "./FuelTypesSelect";
import VehicleYearSelect from "./VehicleYearSelect";
import VehicleMakeModelSelect from "./VehicleMakeModelSelect";

import {
  ALT_VEHICLE_TYPES,
  ALT_VEHICLE_FUEL_TYPES,
} from "../../../helpers/components/vehicles";

const VehicleForm = ({
  make,
  model,
  year,
  fuelType,
  vehicleType,
  id,
  availableFuelTypes,
  editVehicleHandler,
}) => {
  if (!vehicleType) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <VehicleMakeModelSelect
            type="makes"
            id={id}
            vehicleValue={make}
            editValue={editVehicleHandler("make")}
            editModel={editVehicleHandler("model")}
          />
        </Grid>
        <Grid item xs={12}>
          <VehicleMakeModelSelect
            type="models"
            id={id}
            vehicleValue={model}
            editValue={editVehicleHandler("model")}
            make={make}
          />
        </Grid>
        <Grid item xs={12}>
          <VehicleYearSelect
            year={year}
            editVehicle={editVehicleHandler("year")}
            noLabel={false}
            id={`vehicleYear-select-${id}`}
            smallFont
          />
        </Grid>
        {availableFuelTypes.length > 1 && (
          <Grid item xs={12}>
            <FuelTypesSelect
              availableFuelTypes={availableFuelTypes}
              fuelType={fuelType}
              editVehicle={editVehicleHandler("fuelType")}
              id={`vehicleFuelSelect-${id}`}
            />
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Select
          size="small"
          label={"Vehicle Type"}
          value={vehicleType || ""}
          editValue={editVehicleHandler("vehicleType")}
          options={ALT_VEHICLE_TYPES}
          smallFont
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Vehicle Make"
          value={make || ""}
          setValue={editVehicleHandler("make")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Vehicle Model"
          value={model || ""}
          setValue={editVehicleHandler("model")}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          size="small"
          label={"Fuel Type"}
          value={fuelType || ""}
          editValue={editVehicleHandler("fuelType")}
          options={ALT_VEHICLE_FUEL_TYPES}
          smallFont
        />
      </Grid>
      <Grid item xs={12}>
        <VehicleYearSelect
          year={year}
          editVehicle={editVehicleHandler("year")}
          noLabel={false}
          id={`vehicleYear-select-${id}`}
          smallFont
        />
      </Grid>
    </Grid>
  );
};
export default VehicleForm;
