import React from "react";

import { Select } from "@aclymatepackages/atoms";

import ScopeThreeCategoryAvatar from "../../atoms/icons/ScopeThreeCategoryAvatar";

import { scopeThreeSpendBasedCategories } from "../../../helpers/components/vendors";

const ScopeThreeCategorySelect = ({
  scopeThreeCategory = "",
  setScopeThreeCategory,
  ...otherProps
}) => {
  const options = scopeThreeSpendBasedCategories.map(
    ({ label, value, subcategory }) => {
      return {
        label,
        value,
        avatar: (
          <ScopeThreeCategoryAvatar scopeThreeCategory={value} size="small" />
        ),
        style: {
          backgroundColor: subcategory,
          color: "white",
        },
      };
    }
  );

  return (
    <Select
      label="Select Scope 3 Category"
      options={options}
      value={scopeThreeCategory}
      editValue={setScopeThreeCategory}
      valueAvatar={
        <ScopeThreeCategoryAvatar
          scopeThreeCategory={scopeThreeCategory}
          size="small"
        />
      }
      size="small"
      {...otherProps}
      style={{ minWidth: "200px" }}
    />
  );
};
export default ScopeThreeCategorySelect;
