import React, { useState } from "react";

import {
  Grid,
  Paper,
  Tooltip,
  Badge,
  IconButton,
  Skeleton,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

import ViewTitleActionLayout from "./ViewTitleActionLayout";

import FilterPopper from "../modules/filter/FilterPopper";
import CollapsibleAlert from "../atoms/notifications/CollapsibleAlert";

const PremiumActionsRow = ({
  chips,
  filterPopperProps,
  type,
  isFiltered,
  actions = [],
  showFilterButton = true,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        wrap="nowrap"
      >
        {chips}
        {showFilterButton && (
          <Grid item>
            <Grid container alignItems="center" wrap="nowrap">
              <FilterPopper
                open={!!anchorEl}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                filterPopperProps={filterPopperProps}
              />
              <Grid item>
                <Tooltip
                  title={`Filter ${type === "primary" ? "transactions" : type}`}
                >
                  <span>
                    <IconButton
                      onClick={(e) =>
                        setAnchorEl((currentAnchorEl) =>
                          currentAnchorEl ? null : e.currentTarget
                        )
                      }
                    >
                      {isFiltered ? (
                        <Badge
                          variant="dot"
                          color="secondary"
                          style={{ zIndex: 0 }}
                        >
                          <FilterListIcon />
                        </Badge>
                      ) : (
                        <FilterListIcon />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              {actions.map((action, idx) => (
                <Grid item key={`premium-action-${idx}`}>
                  {action}
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const DashboardViewLayout = ({
  viewLoading,
  title,
  subtitle,
  premiumActions,
  primaryAction,
  secondaryAction,
  graph,
  table,
  avatar,
  type,
  chips,
  filterPopperProps,
  isFiltered,
  alerts = [],
  tableAction,
  showFilterButton = true,
}) => (
  <>
    <Grid container direction="column" rowGap={3}>
      {!!alerts.length &&
        alerts.map((alert, idx) => (
          <CollapsibleAlert
            key={`collapsible-alert-${idx}`}
            {...alert}
            idx={idx}
          />
        ))}
      <ViewTitleActionLayout
        viewLoading={viewLoading}
        avatar={avatar}
        type={type}
        title={title}
        subtitle={subtitle}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      />
      {viewLoading ? (
        <Grid item>
          <Skeleton height={60} style={{ width: "100%" }} />
        </Grid>
      ) : (
        <PremiumActionsRow
          chips={chips}
          filterPopperProps={filterPopperProps}
          type={type}
          isFiltered={isFiltered}
          actions={premiumActions}
          showFilterButton={showFilterButton}
        />
      )}
      {graph && <Grid item>{graph}</Grid>}
      {tableAction && <Grid item>{tableAction}</Grid>}
      <Grid item style={{ maxWidth: "100%" }}>
        {viewLoading ? (
          <Skeleton variant="rectangular" height={250} />
        ) : (
          <Paper component="div" style={{ overflow: "hidden", zIndex: 0 }}>
            {table}
          </Paper>
        )}
      </Grid>
    </Grid>
  </>
);

export default DashboardViewLayout;
