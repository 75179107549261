import React, { useState, useEffect } from "react";

import {
  Grid,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import { lbsToTons, kgsToTons, kmToMiles } from "@aclymatepackages/converters";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { Select, TextField, ToggleButtons } from "@aclymatepackages/atoms";
import { calcShippingEmissionsTons } from "@aclymatepackages/calcs/travel";

import PlacesAutocomplete from "../autocomplete/PlacesAutocomplete";

import UnitsSelect from "../UnitsSelect";

import { fetchDirectionsMileage } from "../../../helpers/utils/apiCalls";
import { setAddress } from "../../../helpers/utils/geography";
import { buildInitialTransactionInputValueFromSchema } from "../../../helpers/components/inputs";

const distanceUnitOptions = [
  { label: "Miles", value: "mi" },
  { label: "Kilometers", value: "km" },
];

const transportationOptions = [
  { title: "Truck", value: "road" },
  { title: "Ship", value: "sea" },
  { title: "Air", value: "air" },
  { title: "Train", value: "rail" },
];

const ShippingInput = ({ emissionData, editEmissionData }) => {
  const {
    weightUnit,
    distanceUnit,
    transportationMethod,
    distanceTraveled,
    shippingWeight,
    startPoint,
    endPoint,
    direction,
  } = emissionData;

  const [distanceInputOption, setDistanceInputOption] = useState(
    distanceTraveled && !startPoint && !endPoint ? "distance" : "start-end"
  );
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    const getAndSetMileage = async () => {
      const totalMileage = await fetchDirectionsMileage(startPoint, endPoint);
      if (typeof totalMileage === "number") {
        setErrorMsg("");
        editEmissionData("distanceTraveled")(totalMileage);
        return totalMileage;
      }
      return setErrorMsg(
        "Unable to calculate distance between these two points"
      );
    };

    if (distanceInputOption === "start-end" && startPoint && endPoint) {
      getAndSetMileage();
    }
  }, [startPoint, endPoint, distanceInputOption, editEmissionData]);

  return (
    <Grid container spacing={2} direction="column" wrap="nowrap">
      <Grid item>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          What is the total shipping weight?
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <TextField
              setValue={editEmissionData("shippingWeight")}
              value={shippingWeight}
              label="Shipping Weight"
              error={
                (shippingWeight && !numbersRegExpTest(shippingWeight)) ||
                shippingWeight < 0
              }
            />
          </Grid>
          <Grid item xs={4}>
            <UnitsSelect
              value={weightUnit}
              setValue={editEmissionData("weightUnit")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          {distanceInputOption === "start-end"
            ? "Where did your shipment travel?"
            : "How far did your shipment travel?"}
        </Typography>
        <Grid container spacing={1} direction="column">
          <Grid item container spacing={1}>
            {distanceInputOption === "distance" ? (
              <>
                <Grid item xs={8}>
                  <TextField
                    setValue={editEmissionData("distanceTraveled")}
                    value={distanceTraveled}
                    label="Distance traveled"
                    error={
                      !numbersRegExpTest(distanceTraveled) ||
                      distanceTraveled < 0
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    label="Distance Unit"
                    options={distanceUnitOptions}
                    value={distanceUnit}
                    editValue={editEmissionData("distanceUnit")}
                    size="small"
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={6}>
                  <PlacesAutocomplete
                    size="small"
                    place={startPoint}
                    editPlace={setAddress((place) =>
                      editEmissionData("startPoint")(place)
                    )}
                    label="Starting Point"
                  />
                </Grid>
                <Grid item xs={6}>
                  <PlacesAutocomplete
                    size="small"
                    place={endPoint}
                    editPlace={setAddress((place) =>
                      editEmissionData("endPoint")(place)
                    )}
                    label="Ending Point"
                  />
                </Grid>
                {errorMsg && (
                  <Grid item sm={12}>
                    <Typography variant="caption" color="error">
                      {errorMsg}
                    </Typography>
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <Grid item>
            {distanceInputOption === "distance" ? (
              <Button
                size="small"
                onClick={() => {
                  setDistanceInputOption("start-end");
                  editEmissionData("distanceTraveled")(null);
                }}
                variant="contained"
                color="primary"
              >
                Use start and end points instead
              </Button>
            ) : (
              <Button
                size="small"
                onClick={() => {
                  editEmissionData("startPoint")(null);
                  editEmissionData("endPoint")(null);
                  editEmissionData("distanceTraveled")(0);
                  setDistanceInputOption("distance");
                }}
                variant="contained"
                color="primary"
              >
                Use distance instead
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          What is the primary shipping method?
        </Typography>
        <RadioGroup row>
          {transportationOptions.map((option, idx) => (
            <FormControlLabel
              value={option.value}
              checked={transportationMethod === option.value}
              control={
                <Radio
                  onChange={(e) =>
                    editEmissionData("transportationMethod")(e.target.value)
                  }
                />
              }
              label={option.title}
              key={idx}
            />
          ))}
        </RadioGroup>
      </Grid>
      <Grid item>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          What is the shipping direction?
        </Typography>
        <ToggleButtons
          buttons={[
            {
              value: "downstream",
              name: "Downstream",
              annotation:
                "Downstream shipping is any shipping of a product down from you to your customers. You can think of this as your distribution.",
            },
            {
              value: "upstream",
              name: "Upstream",
              annotation:
                "Upstream shipping is any shipping from one of your vendors down to you. You can think of this as your supply chain.",
            },
          ]}
          value={direction}
          onChange={editEmissionData("direction")}
        />
      </Grid>
    </Grid>
  );
};

/*SCHEMA
{
  weightUnit: string,
  distanceUnit: string,
  transportationMethod: string,
  distanceTraveled: string,
  shippingWeight: number,
  startPoint: addressSchema,
  endPoint: addressSchema,
  direction: "downtstream" | "upstream",
}
*/
const useShippingInput = ({ transaction, onSave }) => {
  const inputSchema = [
    { field: "weightUnit", defaultValue: "lbs" },
    { field: "distanceUnit", defaultValue: "mi" },
    { field: "transportationMethod" },
    { field: "distanceTraveled" },
    { field: "shippingWeight" },
    { field: "startPoint" },
    { field: "endPoint" },
    { field: "direction", defaultValue: "downstream" },
  ];

  const [emissionData, setEmissionData] = useState(
    buildInitialTransactionInputValueFromSchema(transaction, inputSchema)
  );

  const editEmissionData = (field) => (value) =>
    editObjectData(setEmissionData, field, value);

  const {
    transportationMethod,
    distanceTraveled,
    shippingWeight,
    direction,
    weightUnit,
    distanceUnit,
  } = emissionData;

  const calcShippingTons = () => {
    if (!shippingWeight || !distanceTraveled || !transportationMethod) {
      return 0;
    }

    const shippingWeightInTons =
      weightUnit === "lbs"
        ? lbsToTons(shippingWeight)
        : weightUnit === "kgs"
        ? kgsToTons(shippingWeight)
        : shippingWeight;

    const shippingDistanceInMiles =
      distanceUnit === "mi" ? distanceTraveled : kmToMiles(distanceTraveled);

    return calcShippingEmissionsTons(
      shippingDistanceInMiles,
      shippingWeightInTons,
      transportationMethod
    );
  };

  const tonsCo2e = calcShippingTons();

  const scopeThreeCategory = direction === "downstream" ? 9 : 4;

  const onTransactionSave = () =>
    onSave({
      ...emissionData,
      tonsCo2e,
      scopeThreeCategory,
    });

  return {
    inputBlock: (
      <ShippingInput
        emissionData={emissionData}
        editEmissionData={editEmissionData}
      />
    ),
    onTransactionSave,
    saveEnabled: shippingWeight && distanceTraveled && transportationMethod,
    tonsCo2e,
  };
};
export default useShippingInput;
