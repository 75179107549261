import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  Button,
  Tooltip,
  IconButton,
  FormGroup,
  FormControlLabel,
  Switch,
  Badge,
  Paper,
  useTheme,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWrench,
  faUniversity,
  faStar,
  faTh,
  faSort,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import {
  faShieldCheck as faShieldCheckSolid,
  faGlobe,
} from "@fortawesome/pro-solid-svg-icons";

import {
  ToggleButtons,
  Popper,
  Autocomplete,
  TextField,
} from "@aclymatepackages/atoms";
import GoogleMap from "@aclymatepackages/google-maps";
import { letterSBoolean } from "@aclymatepackages/formatters";
import {
  lbsToTons,
  arrayToString,
  hexToRgba,
} from "@aclymatepackages/converters";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { useLayoutHelpers } from "@aclymatepackages/themes";

import OffsetDetailsPurchaseSlider from "./OffsetDetailsPurchaseSlider";
import ProjectSummaryDisplay from "./ProjectSummaryDisplay";
import OffsetProjectCard from "./OffsetProjectCard";
import Link from "../../atoms/mui/Link";
import FilterPopper from "../filter/FilterPopper";

import { unSdgs } from "../../../helpers/components/display-lists/projects";
import {
  sortFilterOptionsAlphabetically,
  createRankedOffsetList,
} from "../../../helpers/components/projects";
import { ProjectsContext } from "../../../helpers/contexts/projects";

const ProjectLocationsFilterInput = ({
  selectedLocations,
  setSelectedLocations,
}) => {
  const { projects } = useContext(ProjectsContext);

  const { countries, states } = selectedLocations;
  const uniqueCountries = [
    ...new Set(projects.map(({ country }) => country)),
  ].map((country) => ({ name: country }));

  const uniqueAmericanStates = [
    ...new Set(
      projects
        .filter(({ country }) => country.toLowerCase() === "united states")
        .map(({ state }) => state)
    ),
  ].map((state) => ({ name: state }));

  const showStatesInput = countries.find(
    ({ name }) => name.toLowerCase() === "united states"
  );

  return (
    <Grid container spacing={2}>
      <Grid item sm={7}>
        <Autocomplete
          multiple
          label="Countries"
          availableOptions={sortFilterOptionsAlphabetically(uniqueCountries)}
          value={countries}
          setValue={(countries) =>
            editObjectData(setSelectedLocations, "countries", countries)
          }
          onDelete={({ name }) =>
            editObjectData(
              setSelectedLocations,
              "countries",
              countries.filter((country) => country.name !== name)
            )
          }
        />
      </Grid>
      {showStatesInput && (
        <Grid item sm={5}>
          <Autocomplete
            multiple
            label="US States"
            availableOptions={sortFilterOptionsAlphabetically(
              uniqueAmericanStates
            )}
            value={states}
            setValue={(states) =>
              editObjectData(setSelectedLocations, "states", states)
            }
            onDelete={({ name }) =>
              editObjectData(
                setSelectedLocations,
                "states",
                states.filter((state) => state.name !== name)
              )
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

const ProjectFilterBlock = ({
  onClick,
  activePopper,
  anchorEl,
  isVerifiedFilter,
  setIsVerifiedFilter,
  isFeaturedFilter,
  setIsFeaturedFilter,
  selectedLocations,
  setSelectedLocations,
  selectedMechanism,
  setSelectedMechanism,
  selectedSdgs,
  setSelectedSdgs,
  resetFilters,
}) => {
  const { projects } = useContext(ProjectsContext);

  const { countries: filterCountries, states: filterStates } =
    selectedLocations;

  const uniqueProjectSdgs = [...new Set(projects.map(({ unSdgs }) => unSdgs))];
  const sdgAutocompleteOptions = uniqueProjectSdgs.map((sdg) => ({
    name: `${sdg}- ${unSdgs[sdg - 1]}`,
  }));

  const onResetFilters = () => {
    resetFilters();
    return onClick();
  };

  const filterRows = [
    {
      icon: faStar,
      filterComponent: (
        <ToggleButtons
          value={isFeaturedFilter}
          onChange={setIsFeaturedFilter}
          buttons={[
            { value: false, name: "all projects" },
            { value: true, name: "featured projects" },
          ]}
        />
      ),
    },
    {
      icon: faShieldCheckSolid,
      filterComponent: (
        <ToggleButtons
          value={isVerifiedFilter}
          onChange={setIsVerifiedFilter}
          buttons={[
            { value: false, name: "Show All Projects" },
            { value: true, name: "Show Only Verified Projects" },
          ]}
        />
      ),
    },
    {
      icon: faGlobe,
      filterComponent: (
        <ProjectLocationsFilterInput
          selectedLocations={selectedLocations}
          setSelectedLocations={setSelectedLocations}
        />
      ),
    },
    {
      icon: faWrench,
      filterComponent: (
        <ToggleButtons
          value={selectedMechanism}
          onChange={setSelectedMechanism}
          buttons={[
            { value: "all", name: "All Mechanisms" },
            { value: "avoidance", name: "Avoidance" },
            { value: "removal", name: "Removal" },
          ]}
        />
      ),
    },
    {
      icon: faUniversity,
      autocomplete: {
        availableOptions: sdgAutocompleteOptions,
        value: selectedSdgs,
        setValue: setSelectedSdgs,
        label: "UN SDGs",
      },
    },
  ];

  const isFiltered =
    isVerifiedFilter ||
    !!filterCountries.length ||
    !!filterStates.length ||
    selectedMechanism !== "all" ||
    !!selectedSdgs.length;

  return (
    <>
      <Grid item>
        <Badge
          variant="dot"
          color="secondary"
          style={{ zIndex: 0 }}
          invisible={!isFiltered}
        >
          <IconButton onClick={onClick}>
            <FontAwesomeIcon icon={faFilter} size="sm" />
          </IconButton>
        </Badge>
      </Grid>
      <FilterPopper
        open={activePopper === "filter" && !!anchorEl}
        filterPopperProps={{
          title: "Filter Projects",
          filterRows,
          resetFilters: onResetFilters,
        }}
        anchorEl={anchorEl}
        setAnchorEl={onClick}
      />
    </>
  );
};

const ProjectSortBlockRows = ({ name, onClick, isSelected, isAsc }) => {
  const { palette } = useTheme();

  return (
    <Grid item container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography
          variant="subtitle1"
          color={isSelected ? "primary" : "inherit"}
        >
          {name}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton
          size="small"
          onClick={() => onClick(false)}
          style={{
            color: isSelected && !isAsc ? palette.primary.main : "inherit",
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => onClick(true)}
          style={{
            color: isSelected && isAsc ? palette.primary.main : "inherit",
          }}
        >
          <ExpandLessIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const ProjectSortBlock = ({
  anchorEl,
  activePopper,
  onClick,
  sortOrder = {},
  setSortOrder,
  preferences,
}) => {
  const { column: sortColumn, isAsc: sortIsAsc } = sortOrder;

  const { projects } = useContext(ProjectsContext);

  const distanceMiSortRow = projects.some(({ distanceMi }) => !!distanceMi)
    ? [{ name: "Distance", column: "distanceMi" }]
    : [];
  const preferencesSortRow = preferences
    ? [{ name: "Preferences", column: "totalScore" }]
    : [];

  const sortingRows = [
    { name: "Tonnes Available", column: "availableCarbonLbs" },
    { name: "Cost", column: "totalThousandLbsCost" },
    ...distanceMiSortRow,
    ...preferencesSortRow,
  ];

  const onSortClick = (column, isAsc) => setSortOrder({ column, isAsc });

  return (
    <>
      <Grid item>
        <IconButton onClick={onClick}>
          <FontAwesomeIcon icon={faSort} size="sm" />
        </IconButton>
      </Grid>
      <Popper
        isOpen={!!anchorEl && activePopper === "sort"}
        anchorEl={anchorEl}
        placement="bottom-end"
        style={{ zIndex: 1099, maxWidth: "400px", minWidth: "300px" }}
        title="Sort Projects"
        onClose={onClick}
        content={sortingRows.map(({ name, column }, idx) => (
          <ProjectSortBlockRows
            key={`project-sort-rows-${idx}`}
            name={name}
            onClick={(isAsc) => onSortClick(column, isAsc)}
            isSelected={column === sortColumn}
            isAsc={sortIsAsc}
          />
        ))}
      />
    </>
  );
};

const ProjectDisplayHeader = ({
  headerHeight,
  subtitle,
  balanceIsNegative,
  showAllProjects,
  setShowAllProjects,
  preferences,
  sortOrder,
  setSortOrder,
  splitView,
  setSplitView,
  projectSearchString,
  setProjectSearchString,
  ...otherProps
}) => {
  const { palette } = useTheme();
  const { isMobile } = useLayoutHelpers();

  const [anchorEl, setAnchorEl] = useState(null);
  const [activePopper, setActivePopper] = useState(null);

  const headerRef = useRef();

  const onPopperClick = (popper) => (e) => {
    if (anchorEl && activePopper === popper) {
      setActivePopper(null);
      return setAnchorEl(null);
    }
    setActivePopper(popper);
    return setAnchorEl(e.currentTarget);
  };

  return (
    <Box
      ref={headerRef}
      position="sticky"
      top={headerHeight + 60 - 1}
      zIndex={100}
      style={{ background: "white" }}
    >
      <Box
        style={{ background: hexToRgba(palette.secondary.main, 0.1) }}
        py={isMobile ? 1 : 2}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant={isMobile ? "h4" : "h2"}>
              Offset Projects
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                {balanceIsNegative && (
                  <Tooltip title="Show all projects and not just those that have enough offsets available to cover your negative balance">
                    <span>
                      <FormGroup row>
                        <FormControlLabel
                          label="Show All Projects"
                          labelPlacement="start"
                          control={
                            <Switch
                              checked={showAllProjects}
                              onChange={() =>
                                setShowAllProjects(
                                  (currentState) => !currentState
                                )
                              }
                            />
                          }
                        />
                      </FormGroup>
                    </span>
                  </Tooltip>
                )}
              </Grid>
              {!(isMobile && splitView === "both") && (
                <ProjectSortBlock
                  anchorEl={anchorEl}
                  onClick={onPopperClick("sort")}
                  activePopper={activePopper}
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                  preferences={preferences}
                />
              )}
              <ProjectFilterBlock
                anchorEl={anchorEl}
                onClick={onPopperClick("filter")}
                activePopper={activePopper}
                {...otherProps}
              />
              <Grid item>
                <ToggleButtons
                  size="small"
                  value={splitView}
                  onChange={(value) => setSplitView(value)}
                  buttons={[
                    {
                      value: "cards",
                      name: <FontAwesomeIcon icon={faTh} />,
                      id: "cards-option",
                    },
                    {
                      value: "both",
                      name: <FontAwesomeIcon icon={faGlobe} />,
                      id: "map-and-cards-option",
                    },
                  ]}
                />
              </Grid>
              <Grid item>
                <TextField
                  value={projectSearchString}
                  setValue={setProjectSearchString}
                  label="Project Name"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Typography variant="subtitle2" color="textSecondary">
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

const ProjectMarkerInfo = ({ marker: project, setOpenMarker }) => {
  const { slug } = project;

  const { displayPageUrl } = useContext(ProjectsContext);

  return (
    <>
      <ProjectSummaryDisplay project={project} />
      <Grid container justifyContent="center">
        <Grid item>
          <Link href={`${displayPageUrl}/${slug}`}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenMarker(null)}
            >
              Buy this project
            </Button>
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

const MapProjectsView = ({ filteredProjects }) => (
  <Paper style={{ height: "100%", overflow: "hidden" }}>
    <GoogleMap
      height="100%"
      markers={filteredProjects}
      InfoWindowComponent={ProjectMarkerInfo}
    />
  </Paper>
);

const NoProjectsBlock = ({
  showAllProjects,
  setShowAllProjects,
  resetFilters,
}) => (
  <Box
    flexGrow={1}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    p={6}
  >
    <Typography variant="h6" align="center" paragraph>
      {showAllProjects
        ? "There are no projects that match your filter criteria. Try clearing your filter criteria."
        : "You're only seeing projects with enough capacity to offset your current negative balance. Try displaying all projects."}
    </Typography>
    <Grid container justifyContent="center">
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            showAllProjects ? resetFilters() : setShowAllProjects(true)
          }
        >
          {showAllProjects ? "Remove your filters" : "Show all projects"}
        </Button>
      </Grid>
    </Grid>
  </Box>
);

const ProjectsDisplayBlock = ({
  ProjectPurchaseFlow,
  currentCarbonBalanceTons,
  carbonBalanceLoading,
  preferences,
  headerHeight,
  actions,
}) => {
  const { isMobile } = useLayoutHelpers();
  const { view } = useParams();
  const history = useHistory();
  const { projects, splitView, setSplitView } = useContext(ProjectsContext);

  const findInitialSortingValue = () => {
    if (projects.some(({ distanceMi }) => !!distanceMi)) {
      return { column: "distanceMi", isAsc: true };
    }
    return { column: "totalThousandLbsCost", isAsc: true };
  };

  const [projectSearchString, setProjectSearchString] = useState("");
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [isVerifiedFilter, setIsVerifiedFilter] = useState(false);
  const [isFeaturedFilter, setIsFeaturedFilter] = useState(false);
  const [sortOrder, setSortOrder] = useState(findInitialSortingValue());
  const [selectedMechanism, setSelectedMechanism] = useState("all");
  const [selectedSdgs, setSelectedSdgs] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState({
    countries: [],
    states: [],
  });

  const { countries: filterCountries, states: filterStates } =
    selectedLocations;

  useEffect(() => {
    if (
      (!carbonBalanceLoading && currentCarbonBalanceTons > 0) ||
      typeof currentCarbonBalanceTons === "undefined"
    ) {
      setShowAllProjects(true);
    }
  }, [currentCarbonBalanceTons, carbonBalanceLoading]);

  useEffect(() => {
    if (preferences) {
      setSortOrder({ column: "totalScore", isAsc: false });
    }
  }, [preferences]);

  const parseViewParam = () => {
    if (!view) {
      return {};
    }

    const viewIsProject = projects.find(({ slug }) => slug === view);
    if (viewIsProject) {
      return { selectedProject: viewIsProject };
    }

    const uniqueCategories = [
      ...new Set(
        projects.flatMap(({ categories }) => categories.map(({ slug }) => slug))
      ),
    ];

    if (uniqueCategories.includes(view)) {
      return { categoriesFilter: view };
    }

    return {};
  };

  const { categoriesFilter, selectedProject } = parseViewParam();

  const projectsWithPreferenceScores = createRankedOffsetList({
    offsetPreferences: preferences,
    projects,
  });

  const filterProjects = () =>
    projectsWithPreferenceScores.filter(
      ({
        availableCarbonLbs,
        categories,
        registryUrl,
        country,
        state,
        mechanism,
        unSdgs,
        offsetProvider,
        name,
      }) => {
        const filteredBySearch = !!projectSearchString
          ? name.toLowerCase().includes(projectSearchString)
          : true;

        const filteredByQuantity =
          showAllProjects ||
          lbsToTons(availableCarbonLbs) > Math.abs(currentCarbonBalanceTons);

        const filteredByCategory =
          !categoriesFilter ||
          categories.find(({ slug }) => categoriesFilter === slug);

        const filteredByIsVerified = !isVerifiedFilter || registryUrl;

        const filteredByIsFeatured = isFeaturedFilter
          ? offsetProvider === "climeco"
          : offsetProvider;

        const filterByCountry =
          !filterCountries.length ||
          filterCountries.some(({ name }) => name === country);

        const filterByState =
          !(filterStates.length && country === "United States") ||
          filterStates.some(({ name }) => name === state);

        const filterByMechanism =
          selectedMechanism === "all" || mechanism === selectedMechanism;

        const filterBySdg = () => {
          if (!selectedSdgs.length) {
            return true;
          }
          const selectedSdgNumbers = selectedSdgs.map(({ name: sdgName }) =>
            Number(sdgName.split("-")[0])
          );

          return unSdgs.some((sdg) => selectedSdgNumbers.includes(sdg));
        };

        return (
          filteredBySearch &&
          filteredByQuantity &&
          filteredByIsFeatured &&
          filteredByCategory &&
          filteredByIsVerified &&
          filterByCountry &&
          filterByState &&
          filterByMechanism &&
          filterBySdg()
        );
      }
    );

  const sortProjects = (projects) => {
    const { column, isAsc } = sortOrder;

    if (isAsc) {
      return [...projects].sort((a, b) => {
        if (a[column] < b[column]) {
          return -1;
        }
        if (b[column] < a[column]) {
          return 1;
        }
        return 0;
      });
    }

    return [...projects].sort((a, b) => {
      if (b[column] < a[column]) {
        return -1;
      }
      if (a[column] < b[column]) {
        return 1;
      }
      return 0;
    });
  };

  const sortFeaturedProjects = (projects) => {
    const isClimeco = ({ offsetProvider }) => offsetProvider === "climeco";

    const featuredProjects = projects.filter(isClimeco);

    const otherProjects = projects.filter((project) => !isClimeco(project));

    const sortedOtherProjects = sortProjects(otherProjects);

    return [...featuredProjects, ...sortedOtherProjects];
  };

  const filteredProjects = filterProjects();
  const projectsCount = filteredProjects.length;

  const buildSubtitle = () => {
    if (!projectsCount) {
      return "There are no projects that match the filter criteria.";
    }

    const firstActionVerb = projectsCount > 1 ? "are" : "is";
    const secondActionVerb = projectsCount > 1 ? "have" : "has";

    const availableProjectsString = !showAllProjects
      ? `that ${secondActionVerb} enough offsets available to cover your current negative balance`
      : "to improve your company's carbon footprint";

    const buildLocationNamesArray = () => {
      const countryNames = filterCountries.map(({ name }) => name);
      const stateNames = filterStates.map(({ name }) => name);

      const isUnitedStatesSelected = countryNames.includes("United States");

      if (isUnitedStatesSelected && stateNames.length) {
        const countriesWithoutUS = countryNames.filter(
          (country) => country !== "United States"
        );
        return [...countriesWithoutUS, ...stateNames];
      }

      return [...countryNames, ...stateNames];
    };

    const locationNamesArray = buildLocationNamesArray();

    const locationsString = locationNamesArray.length
      ? `from ${arrayToString(locationNamesArray)}`
      : "";

    return `There ${firstActionVerb} ${projectsCount} ${
      categoriesFilter || ""
    } offset project${letterSBoolean(
      projectsCount
    )} ${locationsString} ${availableProjectsString}.`;
  };

  const resetFilters = () => {
    setIsVerifiedFilter(false);
    setSelectedLocations({ countries: [], states: [] });
    setIsFeaturedFilter(false);
    setSelectedMechanism("all");
    setSelectedSdgs([]);
    return setSelectedMechanism("all");
  };

  const headerProps = {
    balanceIsNegative: currentCarbonBalanceTons < 0,
    preferences,
    showAllProjects,
    setShowAllProjects,
    isFeaturedFilter,
    setIsFeaturedFilter,
    selectedLocations,
    setSelectedLocations,
    isVerifiedFilter,
    setIsVerifiedFilter,
    selectedMechanism,
    setSelectedMechanism,
    selectedSdgs,
    setSelectedSdgs,
    subtitle: buildSubtitle(),
    splitView,
    setSplitView,
    projectSearchString,
    setProjectSearchString,
  };

  return (
    <>
      {selectedProject && (
        <OffsetDetailsPurchaseSlider
          project={selectedProject}
          onSlideClose={() => history.goBack()}
          ProjectPurchaseFlow={ProjectPurchaseFlow}
        />
      )}
      {actions && (
        <Box display="flex" rowGap={2} flexDirection="column" flexWrap="nowrap">
          {actions.map((action, idx) => (
            <React.Fragment key={`offset-action-${idx}`}>
              {action}
            </React.Fragment>
          ))}
        </Box>
      )}
      <ProjectDisplayHeader
        headerHeight={headerHeight}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        resetFilters={resetFilters}
        {...headerProps}
      />
      <Box
        style={{
          width: "100%",
          overflow: "hidden",
          height: "calc(100% - 96px)",
        }}
        display="flex"
        columnGap={2}
        flexGrow={1}
        position="relative"
      >
        <Grid
          style={splitView === "both" ? { position: "absolute", inset: 0 } : {}}
          container
          spacing={2}
        >
          {!(isMobile && splitView === "both") && (
            <Grid
              item
              md={splitView === "both" ? 5 : 12}
              lg={splitView === "both" ? 7 : 12}
              style={{ height: "100%" }}
            >
              <Box
                style={{
                  maxHeight: "100%",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
                display="flex"
                flexDirection="column"
              >
                {!!projectsCount && (
                  <Grid container spacing={2}>
                    {sortFeaturedProjects(filteredProjects).map((project) => (
                      <OffsetProjectCard
                        key={`project-${project.id}-card`}
                        project={project}
                        isFullScreen={splitView === "cards"}
                      />
                    ))}
                  </Grid>
                )}
                {!projectsCount && (
                  <NoProjectsBlock
                    showAllProjects={showAllProjects}
                    setShowAllProjects={setShowAllProjects}
                    resetFilters={resetFilters}
                  />
                )}
              </Box>
            </Grid>
          )}
          {splitView === "both" && (
            <Grid item xs={12} md={7} lg={5}>
              <MapProjectsView filteredProjects={filteredProjects} />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};
export default ProjectsDisplayBlock;
