import React, { useState } from "react";
import CSVreader from "react-csv-reader";

import {
  Box,
  Typography,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { Select, DefaultPaper } from "@aclymatepackages/atoms";
import { formatDollars } from "@aclymatepackages/formatters";
import { editRowData } from "@aclymatepackages/array-immutability-helpers";
import { mergeMainTheme, mergeDarkTheme } from "@aclymatepackages/themes";

import { isObjectEmpty } from "../../helpers/otherHelpers";
import {
  firebaseArrayUnionObj,
  useCachedFirebaseCrud,
} from "../../helpers/firebase";

const parseCsv = (data, knownFields) => {
  const knownFieldNames = knownFields.map((row) => row.name.toLowerCase());

  const matchKnownFields = (array) =>
    knownFieldNames.every((arrayEl) =>
      array.map((arrayEl) => arrayEl.toLowerCase()).includes(arrayEl)
    );

  const headerRowIdx = data.findIndex((row) => matchKnownFields(row));
  if (headerRowIdx < 0) {
    return null;
  }

  const headerRow = data[headerRowIdx];

  const headerColumns = knownFields.map((fieldRow) => {
    const headerIndex = headerRow
      .map((column) => column.toLowerCase())
      .findIndex((headerEl) => headerEl === fieldRow.name.toLowerCase());
    const fieldName = fieldRow.field;
    return { headerIndex, fieldName };
  });

  const headerIndexes = headerColumns.map((row) => row.headerIndex);

  const dataArray = data.slice(headerRowIdx + 1, data.length);

  return dataArray.map((row) => {
    const rowObj = {};
    row.forEach((rowEl, rowIdx) => {
      if (headerIndexes.includes(rowIdx)) {
        const fieldName = headerColumns.find(
          (row) => row.headerIndex === rowIdx
        ).fieldName;
        rowObj[fieldName] =
          fieldName.toLowerCase() === "mileage" ? Number(rowEl) : rowEl;
      }
    });
    return rowObj;
  });
};

const allCellsFilled = (row) =>
  row.reduce(
    (allCellsFilled, currentCell) => allCellsFilled && !!currentCell,
    true
  );

const useOnProcessData = () => {
  const { newCollectionDoc } = useCachedFirebaseCrud();
  return async ({
    fileInfo,
    docType,
    processData,
    data,
    matchedHeaders,
    storedCsvs,
    originalFileObj,
  }) => {
    const csvIdObj =
      docType !== "employees"
        ? await newCollectionDoc("loadedCsvs", {
            ...fileInfo,
            date: new Date(),
            docType,
          }).then((csvId) => ({ csvId }))
        : {};

    return await processData({
      ...csvIdObj,
      data: parseCsv(data, matchedHeaders),
      fileName: fileInfo.name,
      matchedHeaders,
      storedCsvs,
      originalFileObj,
    });
  };
};

const FileSelectBlock = ({
  setCsvData,
  setHeaderIdx,
  fileInfo,
  setFileInfo,
  knownFields,
  isCsvUploaded,
  setOriginalFileObj,
  requiredFields,
}) => {
  const { name: fileName } = fileInfo;

  const findHeaderRowIdx = (data) => {
    if (!Array.isArray(data)) {
      return null;
    }

    const knownHeaders = knownFields.map(({ header }) => header.toLowerCase());
    const knownHeaderMatches = data.map(
      (row) =>
        row.filter((cell) => knownHeaders.includes(cell.toLowerCase())).length
    );

    const matchedHeaderRows = knownHeaderMatches.filter((count) => count);
    if (!matchedHeaderRows.length) {
      const headerRowIdx = data.findIndex((row) => allCellsFilled(row));
      return headerRowIdx === -1 ? 0 : headerRowIdx;
    }

    const { idx: maxMatchedHeadersIdx } = knownHeaderMatches.reduce(
      ({ matches, idx: matchedIdx }, curr, idx) =>
        Number(curr) > Number(matches)
          ? { matches: curr, idx }
          : { matches, idx: matchedIdx },
      { matches: 0, idx: 0 }
    );
    return maxMatchedHeadersIdx;
  };

  const onCsvUpload = async (data, fileInfo, originalFile) => {
    const headerIdx = findHeaderRowIdx(data);

    setFileInfo(fileInfo);
    setCsvData(data);
    setOriginalFileObj(originalFile);
    setHeaderIdx(headerIdx);

    //TODO: figure out what to do with this
    // const headers = data[headerIdx];

    // if (!storedCsvs?.length) {
    //   return setUploadStep(1);
    // }

    // const filteredStoredCsvs = storedCsvs.filter(
    //   (storedCsv) => storedCsv.docType === docType
    // );

    // if (!filteredStoredCsvs.length) {
    //   return setUploadStep(1);
    // }

    // const matchedCsv = filteredStoredCsvs.find((storedCsv) =>
    //   storedCsv.headers.reduce(
    //     (booleanAcc, storedHeader, idx) =>
    //       storedHeader === headers[idx] && booleanAcc,
    //     true
    //   )
    // );

    // if (matchedCsv) {
    //   const { matchedHeaders } = matchedCsv;
    //   setUploadStep(3);
    //   setMatchedHeaders(matchedHeaders);
    //   return onProcessData({
    //     processData,
    //     data,
    //     fileInfo,
    //     matchedHeaders,
    //     docType,
    //   });
    // }
    // return setUploadStep(1);
  };

  return (
    <>
      <Box p={2}>
        <Grid container alignItems="center" direction="column" spacing={2}>
          {requiredFields && (
            <Grid item>
              <ThemeProvider theme={mergeMainTheme}>
                <DefaultPaper>
                  <Typography variant="h4">
                    There's no need for a template
                  </Typography>
                  <Typography
                    variant="sutbtitle1"
                    color="textSecondary"
                    paragraph
                  >
                    Our CSV loader is designed to handle your data in any format
                    as long as you can identify the following columns:
                  </Typography>
                  <Grid container direction="column" spacing={1}>
                    {requiredFields.map(({ label, description, icon }, idx) => (
                      <Grid
                        item
                        key={`required-field-${idx}`}
                        container
                        spacing={2}
                        alignItems="center"
                        wrap="nowrap"
                      >
                        <Grid
                          item
                          xs={1}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <FontAwesomeIcon icon={icon} size="xl" />
                        </Grid>
                        <Grid item>
                          <Typography variant="h6" display="inline">
                            {label}-{" "}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            display="inline"
                          >
                            {description}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </DefaultPaper>
              </ThemeProvider>
            </Grid>
          )}
          <Grid item>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={mergeMainTheme}>
                <Button
                  variant="contained"
                  color="secondary"
                  id="csv-upload-btn"
                  disabled={isCsvUploaded}
                >
                  <label htmlFor="file-upload-input">Upload a file</label>
                </Button>
              </ThemeProvider>
            </StyledEngineProvider>
            <CSVreader
              onFileLoaded={(data, fileInfo, originalFile) =>
                onCsvUpload(data, fileInfo, originalFile)
              }
              inputId="file-upload-input"
              inputStyle={{ display: "none" }}
            />
          </Grid>
          {fileName && (
            <Grid item>
              <Typography variant="body2" align="center">
                {fileName}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

const CsvTableHead = ({ headers, editHeaders, fieldOptions }) => {
  const allFieldOptions = [{ label: "None", value: "none" }, ...fieldOptions];

  const onFieldSelect = (rowIdx) => (field) => {
    editRowData(rowIdx, editHeaders)("field", field);
    const existingFieldColumnIdx = headers.findIndex(
      (header) => header.field === field
    );
    if (existingFieldColumnIdx !== -1) {
      return editRowData(existingFieldColumnIdx, editHeaders)("field", "none");
    }
    return;
  };

  const HeadCell = ({ header, idx }) => {
    const { name, field } = header;

    return (
      <TableCell>
        <Box py={1}>
          <Select
            options={allFieldOptions}
            value={field}
            editValue={onFieldSelect(idx)}
            size="small"
            id={`head-column-select-${idx}`}
          />
        </Box>
        {name}
      </TableCell>
    );
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mergeMainTheme}>
        <TableHead style={{ backgroundColor: "white" }}>
          <TableRow style={{ verticalAlign: "top" }} id="csv-table-head">
            {headers.map((header, idx) => (
              <HeadCell
                key={`header-row-cell-${idx}`}
                header={header}
                idx={idx}
              />
            ))}
          </TableRow>
        </TableHead>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const CsvTable = ({
  data,
  headerIdx,
  setHeaderIdx,
  headers,
  editHeaders,
  fieldOptions,
  docType,
}) => {
  const maxRowWidth = data.reduce(
    (maxWidth, currentRow) =>
      maxWidth < currentRow.length ? currentRow.length : maxWidth,
    0
  );

  const boldStyleTernary = (rowIdx) =>
    !headers && headerIdx === rowIdx ? { fontWeight: "700" } : {};

  const fixFormatDollars = (cell, docType, row) => {
    if (!data || Array.isArray(data) === false) {
      return cell;
    }

    const newHeaderRowIdx = data.findIndex(({ id }) => id === headerIdx);

    const cardHeaderRow =
      headers?.map((header) => header.name) ||
      data[newHeaderRowIdx < 0 ? 0 : newHeaderRowIdx].data;

    const cardHeaderIdx = cardHeaderRow.findIndex(
      (header) => header.toLowerCase() === "card"
    );

    const isColumnSameAsCard =
      row.findIndex((rowItem) => rowItem === cell) === cardHeaderIdx;

    if (!!Number(cell) && docType === "transactions" && !isColumnSameAsCard) {
      return formatDollars(cell);
    }
    return cell;
  };

  const CsvTableRow = ({ row, rowIdx }) => {
    const filledCellsSum = row.reduce(
      (sum, cell) => (!!cell ? sum + 1 : sum),
      0
    );

    const colSpan = allCellsFilled(row)
      ? 1
      : Math.floor(row.length / filledCellsSum);

    const colSpanSum = colSpan * filledCellsSum;

    const isSelectedHeaderRow = headerIdx === rowIdx;

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider
          theme={isSelectedHeaderRow ? mergeMainTheme : mergeDarkTheme}
        >
          <TableRow
            hover={!headers}
            selected={!headers && headerIdx === rowIdx}
            colSpan={maxRowWidth}
            onClick={!headers ? () => setHeaderIdx(rowIdx) : () => {}}
            style={{
              backgroundColor:
                !headers && isSelectedHeaderRow ? "white" : "inherit",
            }}
            id={`csv-row-${rowIdx}`}
          >
            {!headers && (
              <>
                <TableCell>
                  {isSelectedHeaderRow ? (
                    <FontAwesomeIcon icon={faArrowRight} size="lg" />
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell style={boldStyleTernary(rowIdx)}>{rowIdx}</TableCell>
              </>
            )}
            {row.map(
              (cell, idx) =>
                (!!cell || rowIdx > headerIdx) && (
                  <TableCell
                    key={`csv-row-cell-${idx}`}
                    style={boldStyleTernary(rowIdx)}
                    colSpan={colSpan}
                  >
                    {fixFormatDollars(cell, docType, row)}
                  </TableCell>
                )
            )}
            {colSpan > 1 && colSpanSum < row.length && (
              <TableCell colSpan={row.length - colSpanSum}></TableCell>
            )}
          </TableRow>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  };

  return (
    <Table size="small">
      {headers && (
        <CsvTableHead
          headers={headers}
          editHeaders={editHeaders}
          fieldOptions={fieldOptions}
        />
      )}
      <TableBody>
        {data.map(({ data, id }) => (
          <CsvTableRow row={data} rowIdx={id} key={`csv-data-row-${id}`} />
        ))}
      </TableBody>
    </Table>
  );
};

const IdHeaderRow = ({ data, headerIdx, setHeaderIdx, docType }) => {
  const tableData = data
    .map((row, idx) => ({ data: row, id: idx }))
    .filter(({ id }) => id > headerIdx - 4 && id < headerIdx + 4);

  // const processHeaders = (headers) => {
  //   const knownHeaders = headers.map((header) => {
  //     const knownField = knownFields.find((field) =>
  //       field.header.toLowerCase().includes(header.toLowerCase())
  //     )?.field;

  //     return { name: header, field: knownField || "none" };
  //   });
  //   return knownHeaders.map((header, idx, self) => {
  //     const isDuplicateMatch =
  //       self.findIndex((selfHeader) => selfHeader.field === header.field) !==
  //       idx;
  //     if (isDuplicateMatch) {
  //       return { ...header, field: "none" };
  //     }
  //     return header;
  //   });
  // };

  // const onNextStep = () => {
  //   nextStep();
  //   return setMatchedHeaders(processHeaders(data[headerIdx]));
  // };

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          style={{ overflowX: "auto", overflowY: "hidden", width: "100%" }}
        >
          <CsvTable
            data={tableData}
            headerIdx={headerIdx}
            setHeaderIdx={setHeaderIdx}
            docType={docType}
          />
        </Grid>
        <Grid item>
          <Typography>
            {`Confirm that row ${headerIdx} contains the headers for your table?`}
          </Typography>
        </Grid>
      </Grid>
      {/* <StepBlockFooter onNextStep={onNextStep} nextButtonLabel="Confirm" /> */}
    </>
  );
};

const buildIdHeaderRow = ({
  setMatchedHeaders,
  knownFields,
  csvData,
  headerIdx,
  setHeaderIdx,
  setColumnLength,
  docType,
}) => {
  const processHeadersRow = (headers) => {
    const truncatedHeaders = headers.filter((header) => header);

    const knownHeaders = truncatedHeaders.map((header) => {
      const knownField = knownFields.find((field) =>
        field.header.toLowerCase().includes(header.toLowerCase())
      )?.field;

      return { name: header, field: knownField || "none" };
    });

    setColumnLength(truncatedHeaders.length);
    return setMatchedHeaders(
      knownHeaders.map((header, idx, self) => {
        const isDuplicateMatch =
          self.findIndex((selfHeader) => selfHeader.field === header.field) !==
          idx;
        if (isDuplicateMatch) {
          return { ...header, field: "none" };
        }
        return header;
      })
    );
  };

  return {
    label: "Confirm Header Rows",
    size: "lg",
    title:
      "We need you to confirm the row in your CSV that contains the headers for your columns",
    input: (
      <IdHeaderRow
        data={csvData}
        headerIdx={headerIdx}
        setHeaderIdx={setHeaderIdx}
        docType={docType}
      />
    ),
    buttonText: "confirm",
    onAdvanceForm: () => processHeadersRow(csvData[headerIdx]),
  };
};

const useMatchColumnHeaders = ({
  data,
  headerIdx,
  matchedHeaders,
  setMatchedHeaders,
  fileInfo,
  docType,
  fieldValidator,
  processData,
  fieldOptions,
  columnLength,
  originalFileObj,
}) => {
  const { updateAccountData } = useCachedFirebaseCrud();
  const onProcessData = useOnProcessData();

  const tableData = data
    .map((row, idx) => {
      const rowWithoutEmptyColumns = row.filter((_, idx) => idx < columnLength);

      return { data: rowWithoutEmptyColumns, id: idx };
    })
    .filter((_, idx) => idx > headerIdx && idx < headerIdx + 3);

  const onNextStep = () => {
    const { success } = fieldValidator(matchedHeaders);

    if (!success) {
      return;
    }

    const filteredMatchedHeaders = matchedHeaders.filter(
      (header) => header.field !== "none"
    );
    setMatchedHeaders(filteredMatchedHeaders);

    const storedHeadersObj = {
      docType,
      headers: data[headerIdx],
      matchedHeaders: filteredMatchedHeaders,
    };

    const storedHeadersUpdateObj = {
      storedCsvs: firebaseArrayUnionObj(storedHeadersObj),
    };

    updateAccountData(storedHeadersUpdateObj);

    return onProcessData({
      processData,
      data,
      matchedHeaders: filteredMatchedHeaders,
      fileInfo,
      docType,
      storedCsvs: [storedHeadersObj],
      originalFileObj,
    });
  };

  const { message: errorMessage } = fieldValidator(matchedHeaders);

  return {
    size: "lg",
    title:
      'Please match your headers to the columns that we need to be able to read your data. Please choose "None" for any column that you want us to ignore.',
    input: (
      <Box
        p={2}
        style={{ overflowX: "auto", overflowY: "hidden", width: "100%" }}
      >
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid item>
            <CsvTable
              data={tableData}
              headers={matchedHeaders}
              headerIdx={headerIdx}
              editHeaders={setMatchedHeaders}
              fieldOptions={fieldOptions}
              docType={docType}
            />
          </Grid>
        </Grid>
      </Box>
    ),
    label: "Match Header Columns",
    onAdvanceForm: onNextStep,
    formAdvanceErrorMessage: errorMessage,
  };
};

const useCsvUploader = ({
  fileInfo: parentFileInfo,
  setFileInfo: parentSetFileInfo,
  processData,
  fieldOptions,
  knownFields,
  fieldValidator,
  docType,
  title,
  firstStepText,
  matchedHeaders: parentMatchedHeaders,
  setMatchedHeaders: setParentMatchedHeaders,
  requiredFields = [],
  submitText,
}) => {
  const [matchedHeaders, setMatchedHeaders] = useState([]);
  const [csvData, setCsvData] = useState([[]]);

  const [headerIdx, setHeaderIdx] = useState(0);
  const [fileInfo, setFileInfo] = useState({});
  const [columnLength, setColumnLength] = useState(0);
  const [isCsvUploaded, setIsCsvUploaded] = useState(false);
  const [originalFileObj, setOriginalFileObj] = useState();

  const usableFileInfo = parentFileInfo || fileInfo;
  const usableSetFileInfo = parentSetFileInfo || setFileInfo;
  const usableMatchedHeaders = parentMatchedHeaders || matchedHeaders;
  const usableSetMatchedHeaders = setParentMatchedHeaders || setMatchedHeaders;

  const filterEmptyRows = (csvDataArr) =>
    csvDataArr.filter(
      (row, index) =>
        index === 0 ||
        requiredFields.every((field, fieldIndex) => row[fieldIndex] !== "")
    );
  const idHeaderRow = buildIdHeaderRow({
    setMatchedHeaders: usableSetMatchedHeaders,
    knownFields,
    csvData: filterEmptyRows(csvData),
    headerIdx,
    setHeaderIdx,
    setColumnLength,
    docType,
  });
  const matchColumnHeaders = useMatchColumnHeaders({
    data: filterEmptyRows(csvData),
    requiredFields,
    headerIdx,
    matchedHeaders: usableMatchedHeaders,
    setMatchedHeaders: usableSetMatchedHeaders,
    fileInfo,
    docType,
    fieldValidator,
    processData,
    fieldOptions,
    columnLength,
    originalFileObj,
  });

  return [
    {
      label: "Select a file",
      title: title || `Upload your ${docType} CSV`,
      subtitle: `${firstStepText} `,
      input: (
        <FileSelectBlock
          csvData={csvData}
          setCsvData={setCsvData}
          setHeaderIdx={setHeaderIdx}
          docType={docType}
          processData={processData}
          fileInfo={usableFileInfo}
          setFileInfo={usableSetFileInfo}
          setMatchedHeaders={usableSetMatchedHeaders}
          knownFields={knownFields}
          isCsvUploaded={isCsvUploaded}
          setOriginalFileObj={setOriginalFileObj}
          requiredFields={requiredFields}
        />
      ),
      buttonDisabled: isObjectEmpty(usableFileInfo),
      onAdvanceForm: () => setIsCsvUploaded(true),
    },
    idHeaderRow,
    {
      ...matchColumnHeaders,
      buttonText: submitText || "Next",
    },
  ];
};
export default useCsvUploader;
