import React, { useState, useEffect, useContext } from "react";

import {
  Box,
  Grid,
  Typography,
  Button,
  Tooltip,
  Chip,
  InputAdornment,
  Tab,
  Tabs,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  List,
} from "@mui/material";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import { TextField } from "@aclymatepackages/atoms";
import { formatDollars, formatDecimal } from "@aclymatepackages/formatters";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { tonsToLbs, lbsToTons, kgsToLbs } from "@aclymatepackages/converters";
import { sumTonsCo2e } from "@aclymatepackages/other-helpers";

import UnitsSelect from "../../inputs/UnitsSelect";
import PurchaseDialogLayout from "../../layouts/PurchaseDialogLayout";
import CircularProgressWithLabel from "../../atoms/loading/CircularProgressWithLabel";
import ListItem from "../../atoms/mui/ListItem";

import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";
import { StripeCustomerContext } from "../../../helpers/contexts/stripeCustomer";
import { googlePurchaseTracking } from "../../../helpers/components/purchase";
import { convertCostToPricePerTon } from "../../../helpers/components/projects";
import { fetchOurApi } from "../../../helpers/utils/apiCalls";
import usePurchasingContext from "../../../helpers/contexts/purchasing";

const formatCostPerTon = (totalThousandLbsCost) =>
  formatDollars(convertCostToPricePerTon(totalThousandLbsCost));

const useCNaughtPriceEstimate = () => {
  const [totalThousandLbsCost, setTotalThousandLbsCost] = useState(0);
  const [isTotalThousandLbsCostLoading, setIsTotalThousandLbsCostLoading] =
    useState(true);

  useEffect(() => {
    if (!totalThousandLbsCost && isTotalThousandLbsCostLoading) {
      fetchOurApi({
        path: "/integrations/cNaught/get-credits-price-quote",
        method: "POST",
        data: {
          lbsToPurchase: 1000,
        },
        callback: ({ totalPrice }) => {
          setTotalThousandLbsCost(totalPrice * 1.25); // 25% aclymate fee

          return setIsTotalThousandLbsCostLoading(false);
        },
      });
    }
  }, [totalThousandLbsCost, isTotalThousandLbsCostLoading]);

  return [totalThousandLbsCost, isTotalThousandLbsCostLoading];
};

const PurchaseByVolume = ({ totalThousandLbsCost }) => {
  const { setPurchaseTons, setPurchaseDollars } = usePurchasingContext();
  const [purchaseVolume, setPurchaseVolume] = useState("");
  const [volumeUnit, setVolumeUnit] = useState("tons");

  const editInputData = (inputValue, setter) => {
    const { volume = purchaseVolume, unit = volumeUnit } = inputValue;

    if (!numbersRegExpTest(volume)) {
      return;
    }

    const purchaseLbs =
      unit === "tons"
        ? tonsToLbs(volume)
        : unit === "kgs"
        ? kgsToLbs(volume)
        : volume;

    const purchaseTons = lbsToTons(purchaseLbs);

    const purchaseDollars = (purchaseLbs / 1000) * totalThousandLbsCost;
    setPurchaseDollars(purchaseDollars);
    setPurchaseTons(purchaseTons);
    return setter(inputValue);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <TextField
          value={purchaseVolume}
          setValue={(volume) =>
            editInputData({ volume }, ({ volume }) => setPurchaseVolume(volume))
          }
          label="How many offsets?"
          error={purchaseVolume && !numbersRegExpTest(purchaseVolume)}
        />
      </Grid>
      <Grid item xs={4}>
        <UnitsSelect
          value={volumeUnit}
          setValue={(unit) =>
            editInputData({ unit }, ({ unit }) => setVolumeUnit(unit))
          }
        />
      </Grid>
    </Grid>
  );
};

const PurchaseByDollars = ({ totalThousandLbsCost }) => {
  const { setPurchaseTons, setPurchaseDollars } = usePurchasingContext();
  const [inputDollars, setInputDollars] = useState("");

  const editPurchaseDollars = (dollars) => {
    if (dollars && !numbersRegExpTest(dollars)) {
      return;
    }

    const lbsToPurchase = (Number(dollars) / totalThousandLbsCost) * 1000;

    setPurchaseTons(lbsToTons(lbsToPurchase));
    setPurchaseDollars(dollars);
    return setInputDollars(dollars);
  };

  return (
    <TextField
      value={inputDollars}
      setValue={editPurchaseDollars}
      label="How much do you want to pay for offsets?"
      error={!!inputDollars && !numbersRegExpTest(inputDollars)}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
    />
  );
};

const PerTonPriceDisplay = ({ dataLoading, totalThousandLbsCost }) => (
  <Grid container direction="column">
    <Grid item>
      {dataLoading ? (
        <CircularProgressWithLabel />
      ) : (
        <Typography variant="h6" color="textPrimary">
          {formatCostPerTon(totalThousandLbsCost)}
        </Typography>
      )}
    </Grid>
    <Grid item>
      <Typography variant="caption" color="textSecondary">
        per ton
      </Typography>
    </Grid>
  </Grid>
);

const ScopeSelectionChip = ({ label, onClick, isSelected, status }) => (
  <TableCell>
    <Chip
      icon={isSelected ? <TaskAltIcon /> : <RadioButtonUncheckedIcon />}
      label={formatDecimal(label)}
      onClick={onClick}
      variant={isSelected ? "filled" : "outlined"}
      disabled={status === "disabled"}
    />
  </TableCell>
);

const ScopeYearSelectionRow = ({
  year,
  isPlatinum,
  diamondTons,
  platinumTons,
  onSelectChip,
  selectedChips,
  accountingYears,
  badge,
}) => {
  const findChipsStatus = ({ type: currentYearType }) => {
    const findPlatinumChipsStatus = () => {
      if (isPlatinum || badge === "platinum" || badge === "diamond") {
        return "disabled";
      }

      const earlierYears = accountingYears.filter(
        (row) => row.year < year && !row.isPlatinum
      );

      if (!earlierYears.length) {
        return "selectable";
      }

      const areEarlierYearsChecked = earlierYears.reduce(
        (acc, { year }) =>
          !!selectedChips.find((chip) => chip.year === year) && acc,
        true
      );

      if (areEarlierYearsChecked) {
        return "selectable";
      }

      return "disabled";
    };

    const findDiamondChipsStatus = () => {
      const isYearSelected = !!selectedChips.find((chip) => chip.year === year);
      if (!isYearSelected && !isPlatinum) {
        return "disabled";
      }

      return "selectable";
    };

    if (currentYearType === "platinum") {
      return findPlatinumChipsStatus();
    }

    return findDiamondChipsStatus();
  };

  const chipsArray = [
    {
      label: platinumTons,
      value: platinumTons,
      type: "platinum",
      alreadyPurchased:
        isPlatinum || badge === "platinum" || badge === "diamond",
    },
    {
      label: diamondTons,
      value: diamondTons,
      type: "diamond",
      alreadyPurchased: badge === "diamond",
    },
  ];

  const chipsWithStatuses = chipsArray.map((chip) => ({
    status: findChipsStatus(chip),
    ...chip,
  }));

  return (
    <TableRow>
      <TableCell>
        <Typography variant="h4">{year}</Typography>
      </TableCell>
      {chipsWithStatuses.map(
        ({ label, type, value, status, alreadyPurchased }, idx) => (
          <ScopeSelectionChip
            key={`scope-selection-chip-${idx}`}
            status={status}
            label={label}
            onClick={() => onSelectChip(type, value)}
            isSelected={
              alreadyPurchased ||
              !!selectedChips.find(
                (chip) => chip?.year === year && chip?.type === type
              )
            }
          />
        )
      )}
    </TableRow>
  );
};

const ScopeBadgeHeaderCell = ({ badge, label }) => (
  <Grid container direction="column" spacing={2} alignItems="center">
    <Grid item>
      <img
        src={`/images/badges/svg/${badge}.svg`}
        style={{ width: "125px" }}
        alt={badge}
      />
    </Grid>
    <Grid>
      <Typography align="center" variant="body1">
        {label}
      </Typography>
    </Grid>
  </Grid>
);

const PurchaseByScope = ({ totalThousandLbsCost }) => {
  const {
    setSelectedScopeChips,
    setPurchaseTons,
    setPurchaseDollars,
    nonDiamondYears,
  } = usePurchasingContext();

  const [selectedChips, setSelectedChips] = useState([]);

  const onSelectChip = (year) => (type, tonsCo2e) =>
    setSelectedChips((currentChips) => {
      const findMatchingChip = (chip) =>
        chip?.year === year && chip?.type === type;

      const handleNewChips = (newChips) => {
        setSelectedScopeChips(newChips);
        const purchaseTons = sumTonsCo2e(newChips);
        const purchaseLbs = tonsToLbs(purchaseTons);
        const purchaseDollars = (purchaseLbs / 1000) * totalThousandLbsCost;
        setPurchaseTons(purchaseTons);
        return setPurchaseDollars(purchaseDollars);
      };

      const existingChip = currentChips.find(findMatchingChip);

      if (existingChip) {
        const newChips = currentChips.filter((chip) => !findMatchingChip(chip));
        handleNewChips(newChips);
        return newChips;
      }

      const newChips = [...currentChips, { year, type, tonsCo2e }];
      handleNewChips(newChips);
      return newChips;
    });

  const headersArray = [
    <Typography variant="body1" align="center">
      Year
    </Typography>,
    <ScopeBadgeHeaderCell badge="platinum-offsets" label="Scope 1+2 tons" />,
    <ScopeBadgeHeaderCell badge="diamond-offsets" label="Scope 3 tons" />,
  ];

  return (
    <Table>
      <TableContainer>
        <TableHead>
          <TableRow style={{ verticalAlign: "bottom" }}>
            {headersArray.map((header, idx) => (
              <TableCell key={`table-head-cell-${idx}`}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {nonDiamondYears.map(({ year, ...otherRowProps }, idx) => (
            <ScopeYearSelectionRow
              key={`scope-year-selection-row-${idx}`}
              selectedChips={selectedChips}
              onSelectChip={onSelectChip(year)}
              year={year}
              accountingYears={nonDiamondYears}
              {...otherRowProps}
            />
          ))}
        </TableBody>
      </TableContainer>
    </Table>
  );
};

const EventListItem = ({ event, onSelectEvent, selectedEvents }) => {
  const { id: eventId, name, tonsCo2e } = event;

  const isEventSelected = selectedEvents.find(({ id }) => id === eventId);

  return (
    <ListItem
      primaryText={name}
      secondaryText={`${formatDecimal(tonsCo2e)} Metric Tons`}
      actions={[
        {
          tooltip: "Select this event to offset",
          onClick: () => onSelectEvent(event),
          icon: isEventSelected ? (
            <TaskAltIcon />
          ) : (
            <RadioButtonUncheckedIcon />
          ),
        },
      ]}
    />
  );
};

const PurchaseByEvents = ({ totalThousandLbsCost }) => {
  const {
    setPurchaseTons,
    setPurchaseDollars,
    eventsThatCanBeOffset,
    selectedEvents,
    setSelectedEvents,
  } = usePurchasingContext();

  const onSelectEvent = (newSelectedEvent) =>
    setSelectedEvents((currentSelectedEvents) => {
      const { id: newlySelectedEventId } = newSelectedEvent;
      const isEventAlreadySelected = currentSelectedEvents.find(
        ({ id }) => id === newlySelectedEventId
      );

      const handleNewSelectedEvents = (newSelectedEvents) => {
        const purchaseTons = sumTonsCo2e(newSelectedEvents);
        const purchaseLbs = tonsToLbs(purchaseTons);
        const purchaseDollars = (purchaseLbs / 1000) * totalThousandLbsCost;

        setPurchaseTons(purchaseTons);
        return setPurchaseDollars(purchaseDollars);
      };

      if (isEventAlreadySelected) {
        const filteredSelectedEvents = currentSelectedEvents.filter(
          ({ id }) => id !== newlySelectedEventId
        );

        handleNewSelectedEvents(filteredSelectedEvents);
        return filteredSelectedEvents;
      }

      const newSelectedEvents = [...currentSelectedEvents, newSelectedEvent];
      handleNewSelectedEvents(newSelectedEvents);
      return newSelectedEvents;
    });

  return (
    <List sx={{ maxHeight: "25rem", overflowY: "auto" }}>
      {eventsThatCanBeOffset.map((event, idx) => (
        <EventListItem
          key={`event-row-${idx}`}
          event={event}
          onSelectEvent={onSelectEvent}
          selectedEvents={selectedEvents}
        />
      ))}
    </List>
  );
};

const VolumeSelectionTabs = ({ totalThousandLbsCost }) => {
  const {
    setPurchaseTons,
    selectedTab,
    setSelectedTab,
    nonDiamondYears,
    eventsThatCanBeOffset,
  } = usePurchasingContext();

  const tabsContent = {
    scopes: <PurchaseByScope totalThousandLbsCost={totalThousandLbsCost} />,
    volume: <PurchaseByVolume totalThousandLbsCost={totalThousandLbsCost} />,
    dollars: <PurchaseByDollars totalThousandLbsCost={totalThousandLbsCost} />,
    events: <PurchaseByEvents totalThousandLbsCost={totalThousandLbsCost} />,
  };

  const scopeTabObj = nonDiamondYears.length
    ? [{ label: "Scopes", value: "scopes" }]
    : [];
  const eventsTabObj = eventsThatCanBeOffset.length
    ? [{ label: "Events", value: "events" }]
    : [];

  return (
    <>
      <Grid item style={{ width: "100%" }}>
        <Tabs
          value={selectedTab}
          onChange={(_, value) => {
            setPurchaseTons(0);
            setSelectedTab(value);
          }}
          variant="full-width"
          indicatorColor="secondary"
        >
          {[
            ...scopeTabObj,
            ...eventsTabObj,
            { label: "Volume", value: "volume" },
            { label: "Dollars", value: "dollars" },
          ].map((tab, idx) => (
            <Tab key={`tab-${idx}`} {...tab} />
          ))}
        </Tabs>
      </Grid>
      <Grid item>{tabsContent[selectedTab]}</Grid>
    </>
  );
};

const FooterAnnotation = ({
  offsetsVolumeError,
  purchaseTons,
  purchaseDollars,
  totalThousandLbsCost,
}) => {
  const DisplayNumber = ({ number, caption }) => (
    <Grid item>
      <Typography variant="h2" align="center">
        {number}
      </Typography>
      <Typography
        display="block"
        align="center"
        variant="caption"
        color="textSecondary"
      >
        {caption}
      </Typography>
    </Grid>
  );

  if (offsetsVolumeError) {
    return (
      <Grid item>
        <Typography color="error" align="center">
          You're trying to buy more offsets than this project currently has
          available
        </Typography>
      </Grid>
    );
  }

  if (purchaseTons) {
    return (
      <Grid
        item
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <DisplayNumber
          number={formatCostPerTon(totalThousandLbsCost)}
          caption="per tonne"
        />
        <Grid item>
          <Typography variant="h1">*</Typography>
        </Grid>
        <DisplayNumber number={formatDecimal(purchaseTons)} caption="tonnes" />
        <Grid item>
          <Typography variant="h1">=</Typography>
        </Grid>
        <DisplayNumber
          number={formatDollars(purchaseDollars)}
          caption="Total Cost"
        />
      </Grid>
    );
  }

  return <></>;
};

const Footer = ({
  project,
  setPurchaseStep,
  dataLoading,
  totalThousandLbsCost,
}) => {
  const { availableCarbonLbs } = project;
  const { purchaseDollars, purchaseTons } = usePurchasingContext();

  const { paymentMethod } = useContext(StripeCustomerContext);
  const { paymentMethodId } = paymentMethod || {};

  const { viewMode } = useContext(PlatformLayoutContext);
  const isNotCompanyViewMode = viewMode !== "company";

  const offsetsVolumeError = lbsToTons(availableCarbonLbs) < purchaseTons;

  const isNextStepDisabled =
    !purchaseTons || offsetsVolumeError || isNotCompanyViewMode || dataLoading;

  const purchaseTrackingObj = {
    project,
    purchaseDollars,
    purchaseTons,
  };

  return (
    <>
      <FooterAnnotation
        offsetsVolumeError={offsetsVolumeError}
        purchaseTons={purchaseTons}
        purchaseDollars={purchaseDollars}
        totalThousandLbsCost={totalThousandLbsCost}
      />
      <Tooltip
        title="You can browse offsets in non-company view mode but not purchase them"
        disableHoverListener={!isNotCompanyViewMode}
      >
        <Grid item container justifyContent="center" spacing={2}>
          {/* <Grid item>We're taking this out for now until we have time to put it back in
            <Button
              variant="contained"
              color="primary"
              disabled={isNextStepDisabled}
              onClick={() => setPurchaseStep("create-estimate")}
            >
              Create an Estimate
            </Button>
          </Grid> */}
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              disabled={isNextStepDisabled}
              onClick={() => {
                googlePurchaseTracking("add_to_cart", purchaseTrackingObj);
                googlePurchaseTracking("begin_checkout", purchaseTrackingObj);
                return setPurchaseStep("ready-to-purchase");
              }}
            >
              {!!paymentMethodId ? "Review Purchase" : "Setup billing"}
            </Button>
          </Grid>
        </Grid>
      </Tooltip>
    </>
  );
};

const PurchaseVolumeSelectionInput = ({
  setPurchaseStep,
  project,
  isCNaughtPurchase,
}) => {
  const {
    availableCarbonLbs,
    totalThousandLbsCost: projectTotalThousandLbsCost,
  } = project;

  const { dataLoading: contextLoading } = usePurchasingContext();

  const [cNaughtTotalThousandLbsCost, isCNaughtTotalThousandLbsCostLoading] =
    useCNaughtPriceEstimate();

  const dataLoading =
    contextLoading ||
    (isCNaughtPurchase && isCNaughtTotalThousandLbsCostLoading);

  const totalThousandLbsCost = isCNaughtPurchase
    ? cNaughtTotalThousandLbsCost
    : projectTotalThousandLbsCost;

  const titles = !isCNaughtPurchase
    ? {
        title: "Purchase offsets from this project",
        subtitle: `${formatDecimal(
          Math.round(availableCarbonLbs)
        )} pounds of carbon are available from this project.`,
      }
    : {
        title: "Purchase offsets from this project portfolio",
      };

  return (
    <PurchaseDialogLayout
      {...titles}
      sideHeader={
        <PerTonPriceDisplay
          dataLoading={dataLoading}
          totalThousandLbsCost={totalThousandLbsCost}
        />
      }
      isLoading={dataLoading}
    >
      <Box p={2}>
        <Grid container spacing={2} direction="column">
          <VolumeSelectionTabs totalThousandLbsCost={totalThousandLbsCost} />
          <Footer
            totalThousandLbsCost={totalThousandLbsCost}
            project={project}
            setPurchaseStep={setPurchaseStep}
            dataLoading={dataLoading}
          />
        </Grid>
      </Box>
    </PurchaseDialogLayout>
  );
};
export default PurchaseVolumeSelectionInput;
