import React, { useContext, useState } from "react";

import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Grid,
  IconButton,
  StyledEngineProvider,
  Tooltip,
  Typography,
  useTheme,
  ThemeProvider,
} from "@mui/material";

import DoneIcon from "@mui/icons-material/Done";
import ShuffleIcon from "@mui/icons-material/Shuffle";

import { Backdrop, DefaultPaper, TextField } from "@aclymatepackages/atoms";
import mainTheme, { mergeDarkTheme } from "@aclymatepackages/themes";
import {
  editRowData,
  addDataRow,
  removeDataRow,
} from "@aclymatepackages/array-immutability-helpers";
import { hexToRgba } from "@aclymatepackages/converters";
import { emailRegExpTest, numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { letterSBoolean } from "@aclymatepackages/formatters";
import { STARTER_TIER_MAXIMUM_NUMBER_EMPLOYEES } from "@aclymatepackages/constants";

import CopyButtonCard from "../../atoms/CopyButtonCard";
import { useNewEmployeeForm } from "../../hooks/employees";
import useCsvUploader from "../../hooks/csvUploader";
import RemovableRow from "../../modules/RemovableRow";
import MultiPartFormLayout from "../flows/MultipartForm";

import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";
import {
  useSharedFormLoading,
  doesFieldExist,
  createErrorObj,
} from "../../../helpers/components/inputs";
import {
  useCachedFirebaseCrud,
  useAccountData,
  useCachedDisplayData,
} from "../../../helpers/firebase";
import useInputType from "../../../helpers/components/display-lists/inputTypes";
import {
  generateRandomPin,
  getAccountCollectionAndId,
} from "../../../helpers/otherHelpers";
import {
  parseStateFromAddress,
  parseZipCodeFromAddress,
} from "../../../helpers/utils/geography";
import { fetchOurApi } from "../../../helpers/utils/apiCalls";
import { analyticsTrack } from "../../../helpers/analytics";
import useAccountingData from "../../../helpers/hooks/accountingData";
import { useStarterTierSubscriptionFlags } from "../../../helpers/hooks/companyData";

const fieldOptions = [
  { label: "First Name", value: "firstName" },
  { label: "Last Name", value: "lastName" },
  { label: "Name", value: "fullName" },
  { label: "Last Name, First Name", value: "lastFirst" },
  { label: "Email Address", value: "email" },
  { label: "Full Home Address", value: "fullAddress" },
  { label: "Street Address", value: "street" },
  { label: "City", value: "city" },
  { label: "State", value: "state" },
  { label: "Zip Code", value: "zipCode" },
];

const knownFields = [
  { header: "first name", field: "firstName" },
  { header: "last name", field: "lastName" },
  { header: "email address", field: "email" },
  { header: "phone", field: "phone" },
  { header: "address", field: "fullAddress" },
  { header: "street", field: "street" },
  { header: "city", field: "city" },
  { header: "state", field: "state" },
  { header: "zip", field: "zipCode" },
];

const calcDescriptionObj = ({ fullAddress, street, city, state, zipCode }) => {
  if (fullAddress) {
    return { description: fullAddress };
  }

  if (street || city || state || zipCode) {
    const description = `${street || ""} ${city || ""} ${
      state ? `${state},` : ""
    } ${zipCode}`;
    return { description };
  }

  return {};
};

const buildAddressObj = (address) => {
  const { fullAddress, street, city, state, zipCode } = address;

  if (!fullAddress && !street && !city && !state && !zipCode) {
    return {};
  }

  const zipObj = fullAddress
    ? { zipCode: parseZipCodeFromAddress(fullAddress) }
    : zipCode
    ? { zipCode }
    : {};

  const descriptionObj = calcDescriptionObj(address);

  const stateObj = state
    ? { state }
    : fullAddress
    ? { state: parseStateFromAddress(fullAddress) } || {}
    : {};

  return {
    address: {
      ...zipObj,
      ...descriptionObj,
      ...stateObj,
    },
  };
};

const formatEmployeeName = ({ firstName, lastName, fullName, firstLast }) => {
  const matchFirstLast = (name) => {
    const firstLastMatch = name.match(/^[a-zA-Z]+,\s?[a-zA-Z]+$/);
    if (!firstLastMatch) {
      return name;
    }

    const [lastName, firstName, ...otherNames] = name
      .split(",")
      .map((name) => name.trim());

    return `${firstName} ${
      otherNames?.length ? otherNames.join(" ") : ""
    } ${lastName}`;
  };

  if (fullName) {
    return matchFirstLast(fullName);
  }

  if (firstName && lastName) {
    return `${firstName.trim()} ${lastName.trim()}`;
  }

  return matchFirstLast(firstLast);
};

const useProcessEmployeeInvites = () => {
  const [existingEmployees] = useCachedDisplayData("employees");
  const { setFormLoading } = useSharedFormLoading();
  const { newCollectionDoc } = useCachedFirebaseCrud();
  const [{ name: companyName }] = useAccountData();
  const { willEmployeeLimitBeReachedFromNewEmployees } =
    useStarterTierSubscriptionFlags();

  return ({ onClose, submitErrorMsg = null, triggerSnackbar }) =>
    async ({ data: employees }) => {
      setFormLoading(true);
      const { id } = getAccountCollectionAndId();

      const filteredEmployees = employees.filter(({ email }) => email);

      if (willEmployeeLimitBeReachedFromNewEmployees(filteredEmployees)) {
        triggerSnackbar(`Adding ${filteredEmployees.length} employees will bring you above the 
          ${STARTER_TIER_MAXIMUM_NUMBER_EMPLOYEES} employee limit. Reduce
          the number of employees to upload in your CSV or upgrade your subscription.
        `);
        onClose();

        return setFormLoading(false);
      }

      const formattedEmployees = filteredEmployees.map((employee) => {
        const { email, startDate } = employee;

        const existingEmployee = existingEmployees.find(
          (existingEmployee) => existingEmployee.email === email
        );

        const existingEmployeeObj = existingEmployee?.length
          ? { existing: true }
          : {};

        const addressObj = buildAddressObj(employee);

        return {
          dateCreated: new Date(),
          origin: "employee",
          name: formatEmployeeName(employee),
          ...addressObj,
          ...existingEmployeeObj,
          email,
          currentLinkedIds: [id],
          links: [
            {
              id,
              name: companyName,
              type: "company",
              startDate,
              status: "unconfirmed",
              employeeStatus: "unconfirmed",
            },
          ],
        };
      });

      const duplicateEmployees = formattedEmployees.filter(
        ({ existing }) => existing
      );

      if (duplicateEmployees.length) {
        const isMultipleEmployees = duplicateEmployees.length > 1;

        triggerSnackbar(
          `${duplicateEmployees.length} employee${letterSBoolean(
            duplicateEmployees
          )} ${
            isMultipleEmployees ? "weren't" : "wasn't"
          } added because they're ${
            isMultipleEmployees ? "" : "a"
          } duplicate${letterSBoolean(duplicateEmployees)} of ${
            isMultipleEmployees ? "" : "an"
          } existing employee${letterSBoolean(duplicateEmployees)}. If ${
            isMultipleEmployees ? "those" : "that"
          } employee${letterSBoolean(duplicateEmployees)} ${
            isMultipleEmployees ? "are" : "is"
          } still unconfirmed, send them ${
            isMultipleEmployees ? "" : "a"
          } reminder${letterSBoolean(
            duplicateEmployees
          )} to fill out their survey${letterSBoolean(duplicateEmployees)}.`
        );
      }

      const newEmployees = formattedEmployees.filter(
        ({ existing }) => !existing
      );
      analyticsTrack("Employees Invited", {
        numberOfEmployees: newEmployees.length,
      });

      await Promise.all(
        newEmployees.map(async (employee) => {
          const { name } = employee;
          const slug = await fetchOurApi({
            path: "/onboarding/new-document-slug",
            method: "POST",
            data: { name, collection: "individuals" },
            callback: ({ slug }) => slug,
          });

          const employeeId = await newCollectionDoc("employees", {
            ...employee,
            slug,
          });

          return await fetchOurApi({
            path: "/survey-emails/employee/invite",
            method: "POST",
            data: {
              employee: { ...employee, id: employeeId },
              companyId: id,
            },
          });
        })
      )
        .then(() => onClose())
        .catch(
          () =>
            submitErrorMsg &&
            submitErrorMsg(
              "An error occurred while sending this email. Please try again."
            )
        );

      return setFormLoading(false);
    };
};

const EditableRow = ({
  firstName,
  lastName,
  email,
  editEmployee,
  submitErrorMsg,
}) => (
  <>
    <Grid item sm={3}>
      <TextField
        label="First Name"
        value={firstName}
        setValue={(value) => editEmployee("firstName", value)}
        error={!!submitErrorMsg && !firstName}
      />
    </Grid>
    <Grid item sm={4}>
      <TextField
        label="Last Name"
        value={lastName}
        setValue={(value) => editEmployee("lastName", value)}
        error={!!submitErrorMsg && !lastName}
      />
    </Grid>
    <Grid item sm={4}>
      <TextField
        label="Email Address"
        value={email}
        setValue={(value) => editEmployee("email", value)}
        error={!!submitErrorMsg && (!email || emailRegExpTest(email))}
      />
    </Grid>
  </>
);

const ManualEmployeeEmailsRow = ({
  employee: { firstName, lastName, email },
  employeeCount,
  editEmployee,
  removeEmployee,
  submitErrorMsg,
  idx,
}) => (
  <>
    {employeeCount > 1 ? (
      <RemovableRow
        rowCount={employeeCount}
        removeRow={removeEmployee}
        idx={idx}
      >
        <EditableRow
          firstName={firstName}
          lastName={lastName}
          email={email}
          editEmployee={editEmployee}
          submitErrorMsg={submitErrorMsg}
        />
      </RemovableRow>
    ) : (
      <Grid container justifyContent="center" spacing={2}>
        <EditableRow
          firstName={firstName}
          lastName={lastName}
          email={email}
          editEmployee={editEmployee}
          submitErrorMsg={submitErrorMsg}
        />
      </Grid>
    )}
  </>
);

const useManualEmployeeEmails = ({ onClose }) => {
  const { activateSnackbar } = useContext(PlatformLayoutContext);

  const processEmployeeInvites = useProcessEmployeeInvites();
  const { willEmployeeLimitBeReachedFromNewEmployees } =
    useStarterTierSubscriptionFlags();

  const [newEmployees, setNewEmployees] = useState([{}]);
  const [submitErrorMsg, setSubmitErrorMsg] = useState("");

  const isStarterTierEmployeeLimitReached =
    willEmployeeLimitBeReachedFromNewEmployees(newEmployees);

  const triggerSnackbar = (snackbarMessage) =>
    activateSnackbar({ message: snackbarMessage, alert: "warning" });

  const areEmployeesValid = () => {
    const allFieldsFilled = newEmployees.reduce(
      (acc, { firstName, lastName, email }) =>
        acc && firstName && lastName && email && emailRegExpTest(email),
      true
    );

    const duplicateEmployees = newEmployees
      .map((employee, idx, employees) => {
        const { email } = employee;
        if (!email) {
          return employee;
        }
        const otherEmployees = employees.filter((_, i) => i !== idx);
        const duplicateEmployeeEmail = otherEmployees.find(
          (employee) => employee.email === email
        );
        if (duplicateEmployeeEmail) {
          return { ...employee, duplicate: true };
        }
        return employee;
      })
      .filter(({ duplicate }) => duplicate);

    if (duplicateEmployees.length) {
      return false;
    }
    if (!allFieldsFilled) {
      return false;
    }
    return true;
  };

  const submitEmployees = async () =>
    await processEmployeeInvites({
      onClose,
      submitErrorMsg,
      triggerSnackbar,
    })({ data: newEmployees });

  return [
    {
      label: "Invite your team",
      title: "Email your employees a survey to track their carbon emissions.",
      input: (
        <Grid item container direction="column" spacing={2} alignItems="center">
          <Grid item style={{ width: "100%" }}>
            {newEmployees.map((employee, idx) => (
              <ManualEmployeeEmailsRow
                key={`manual-employee-email-row-${idx}`}
                employee={employee}
                employeeCount={newEmployees.length}
                editEmployee={editRowData(idx, setNewEmployees)}
                removeEmployee={removeDataRow(idx, setNewEmployees)}
                submitErrorMsg={submitErrorMsg}
                setSubmitErrorMsg={setSubmitErrorMsg}
                idx={idx}
              />
            ))}
          </Grid>
          <Grid item>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={mainTheme}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => addDataRow(setNewEmployees, {})}
                  disabled={isStarterTierEmployeeLimitReached}
                >
                  New Employee
                </Button>
              </ThemeProvider>
            </StyledEngineProvider>
          </Grid>
        </Grid>
      ),
      buttonText: "Send Survey",
      buttonDisabled: !areEmployeesValid(),
      formAdvanceErrorMessage:
        isStarterTierEmployeeLimitReached &&
        `Adding ${newEmployees.length} employees will bring you above the 
      ${STARTER_TIER_MAXIMUM_NUMBER_EMPLOYEES} employee limit. Reduce
      the number of employees to email or upgrade your subscription.`,
      onAdvanceForm: submitEmployees,
    },
  ];
};

const useEmployeesCsvUploader = ({ onClose }) => {
  const { activateSnackbar } = useContext(PlatformLayoutContext);
  const processEmployeeInvites = useProcessEmployeeInvites();

  const triggerSnackbar = (snackbarMessage) =>
    activateSnackbar({ message: snackbarMessage, alert: "warning" });

  const fieldValidator = (headers) => {
    if (!doesFieldExist(headers, "email")) {
      return createErrorObj(
        "The email field is required to invite your employees"
      );
    }
    if (
      !(
        (doesFieldExist(headers, "firstName") &&
          doesFieldExist(headers, "lastName")) ||
        doesFieldExist(headers, "fullName")
      )
    ) {
      return createErrorObj("You must select fields for your employees names");
    }

    return { success: true };
  };

  const employeeCsvUploaderSteps = useCsvUploader({
    title: "Invite your team",
    knownFields,
    fieldOptions,
    docType: "employees",
    fieldValidator,
    processData: processEmployeeInvites({
      onClose,
      triggerSnackbar,
    }),
    firstStepText:
      "Upload a CSV of your employees and we'll invite them to fill out their profiles for Aclymate.",
    submitText: "Send Survey",
  });

  return employeeCsvUploaderSteps;
};

const OptionCard = ({ option, chooseOption, isSelected, color }) => {
  const theme = useTheme();
  const { isStarterTier } = useStarterTierSubscriptionFlags();

  const { title, subtitle, IconComponent, value, onClick } = option;

  const isDisabled = isStarterTier && value === "email-link";

  const borderStyleObj = isSelected ? { border: `thick solid ${color}` } : {};
  const disabledStyleObj = isDisabled
    ? { backgroundColor: theme.palette.backgroundGray.dark, opacity: 0.8 }
    : {};

  return (
    <Tooltip
      title="This feature is disabled in the Starter plan"
      disableHoverListener={!isDisabled}
    >
      <div style={{ width: "100%", height: "100%" }}>
        <ButtonBase
          onClick={() => {
            if (onClick) {
              onClick();
            }
            return chooseOption(value);
          }}
          style={{ height: "100%", width: "100%" }}
          disabled={isDisabled}
        >
          <DefaultPaper
            style={{
              height: "100%",
              width: "100%",
              position: "relative",
              ...borderStyleObj,
              ...disabledStyleObj,
            }}
          >
            {isSelected && (
              <div
                style={{
                  backgroundColor: color,
                  position: "absolute",
                  right: 0,
                  top: 0,
                  padding: `calc(${theme.spacing(1)} / 2)`,
                  borderRadius: "0 0 0 4px",
                  color: "white",
                }}
              >
                <DoneIcon />
              </div>
            )}
            <Typography variant="body1">{title}</Typography>
            {IconComponent && (
              <IconComponent style={{ color }} fontSize="large" />
            )}
            {subtitle && (
              <Typography
                variant="caption"
                color="textSecondary"
                display="block"
              >
                {subtitle}
              </Typography>
            )}
          </DefaultPaper>
        </ButtonBase>
      </div>
    </Tooltip>
  );
};

const EmployeeSurveyLinkAndPin = ({ employeeSurveyLinkAndPinObj, color }) => {
  const { updateAccountData } = useCachedFirebaseCrud();

  return employeeSurveyLinkAndPinObj.map(
    ({ shuffleIcon, ...otherProps }, idx) => (
      <Grid item xs={5} key={`link-and-pin-obj-${idx}`}>
        <CopyButtonCard
          {...otherProps}
          color={color}
          secondaryAction={
            shuffleIcon && (
              <Tooltip title="Shuffle">
                <IconButton
                  style={{ padding: "8px" }}
                  onClick={() =>
                    updateAccountData({
                      employeeSurveyPin: generateRandomPin(),
                    })
                  }
                  size="large"
                >
                  {shuffleIcon}
                </IconButton>
              </Tooltip>
            )
          }
        />
      </Grid>
    )
  );
};

const AddingEmployeeOptions = ({
  onClose,
  accountId,
  selectedOption,
  setSelectedOption,
  selectedSubOption,
  setSelectedSubOption,
  employeeSurveyPin,
  isSliderLeftContent,
}) => {
  const theme = useTheme();

  const { updateAccountData } = useCachedFirebaseCrud();
  const [{ employeeCountConfirmed }] = useAccountData();

  const [employeeConfirmNumber, setEmployeeConfirmNumber] = useState();

  const {
    title,
    color,
    backdropColor,
    primaryIcon: PrimaryIcon,
    options,
    subOptions,
  } = useInputType("employees");

  const checkCompanySlugSetConfirmEmployeeCount = async () =>
    await updateAccountData({
      employeeCount: employeeConfirmNumber,
      employeeCountConfirmed: true,
      employeeSurveyPin: generateRandomPin(),
    });

  const { id: companyId } = getAccountCollectionAndId(accountId);
  const employeeSurveyLinkAndPinObj = [
    {
      title: "Employee Survey Link",
      text: `https://${window.location.host}/employee-survey/${companyId}/new-employee`,
    },
    {
      title: "Employee Survey Pin",
      text: employeeSurveyPin,
      shuffleIcon: <ShuffleIcon style={{ fontSize: "16px" }} />,
    },
  ];

  const sliderLeftContentStyling = isSliderLeftContent && { right: "40%" };

  return (
    <Backdrop
      open
      style={{
        color: "white",
        zIndex: 1300,
        ...sliderLeftContentStyling,
      }}
      onFormClose={!isSliderLeftContent && (() => onClose(false))}
      backgroundColor={backdropColor && hexToRgba(backdropColor, 0.85)}
    >
      <Box
        style={{
          height: "100%",
          padding: theme.spacing(4),
        }}
      >
        <Grid container direction="column" spacing={4} alignItems="center">
          <Grid item>
            <Avatar
              style={{
                backgroundColor: color,
                height: 100,
                width: 100,
              }}
            >
              <PrimaryIcon style={{ fontSize: "4rem" }} />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              How would you like to add your team?
            </Typography>
          </Grid>
          <Grid container item spacing={2}>
            {options.map((option, idx) => (
              <Grid key={`input-option-${idx}`} item xs={4}>
                <OptionCard
                  chooseOption={(value) => setSelectedOption(value)}
                  option={option}
                  isSelected={option.value === selectedOption}
                  color={color}
                />
              </Grid>
            ))}
          </Grid>
          {selectedOption === "csvOrManualEmail" && (
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h6">
                  Select an option to invite your team
                </Typography>
              </Grid>
              <Grid item container justifyContent="center" spacing={2}>
                {subOptions.map((subOption, idx) => (
                  <Grid key={`input-option-${idx}`} item xs={4}>
                    <OptionCard
                      chooseOption={(value) => setSelectedSubOption(value)}
                      option={subOption}
                      isSelected={subOption.value === selectedSubOption}
                      color={color}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
          {selectedOption === "email-link" && (
            <>
              {!employeeCountConfirmed && (
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                  spacing={2}
                >
                  <Grid item style={{ width: "40%" }}>
                    <StyledEngineProvider injectFirst>
                      <ThemeProvider theme={mergeDarkTheme}>
                        <TextField
                          label="Please confirm how many employees you have"
                          value={employeeConfirmNumber}
                          setValue={setEmployeeConfirmNumber}
                        />
                      </ThemeProvider>
                    </StyledEngineProvider>
                  </Grid>
                  <Grid item>
                    <StyledEngineProvider injectFirst>
                      <ThemeProvider theme={mainTheme}>
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          disabled={
                            !employeeConfirmNumber ||
                            !numbersRegExpTest(employeeConfirmNumber) ||
                            employeeConfirmNumber <= 0
                          }
                          onClick={checkCompanySlugSetConfirmEmployeeCount}
                        >
                          Generate Link And Pin
                        </Button>
                      </ThemeProvider>
                    </StyledEngineProvider>
                  </Grid>
                </Grid>
              )}
              {employeeCountConfirmed && (
                <Grid item container justifyContent="center" spacing={2}>
                  <EmployeeSurveyLinkAndPin
                    employeeSurveyLinkAndPinObj={employeeSurveyLinkAndPinObj}
                    color={color}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
    </Backdrop>
  );
};

const AddEmployeesInputs = ({ onClose, isSliderLeftContent }) => {
  const accountId = window.sessionStorage.getItem("accountId");
  const [{ employeeSurveyPin, startDate: companyStartDate }] = useAccountData();
  const [{ mostRecentAccountingDate }] = useAccountingData();

  const newEmployeeForm = useNewEmployeeForm({
    onClose,
    companyStartDate,
    mostRecentAccountingDate,
  });
  const emailEmployeesForm = useManualEmployeeEmails({ onClose });
  const uploadEmployeeCsvForm = useEmployeesCsvUploader({ onClose });

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedSubOption, setSelectedSubOption] = useState("");

  const forms = {
    add: newEmployeeForm,
    csv: uploadEmployeeCsvForm,
    emailManual: emailEmployeesForm,
  };
  const formsLoadingText = {
    csv: "Please wait while we upload your employees...",
    add: "Please wait while we load your new employee...",
    emailManual: "Please wait while we email your new employee...",
  };

  return (
    <>
      {selectedOption === "add" ? (
        <MultiPartFormLayout
          type="employees"
          forms={forms[selectedOption]}
          setOption={setSelectedOption}
          selectedOption={selectedOption}
          onClose={!isSliderLeftContent && onClose}
          submitLoadingText={formsLoadingText[selectedOption]}
          displayOptions={selectedOption}
          style={isSliderLeftContent && { right: "40%" }}
        />
      ) : selectedSubOption === "csv" || selectedSubOption === "emailManual" ? (
        <MultiPartFormLayout
          type="employees"
          forms={forms[selectedSubOption]}
          setOption={setSelectedSubOption}
          selectedOption={selectedSubOption}
          onClose={!isSliderLeftContent && onClose}
          displayOptions={selectedSubOption}
          style={isSliderLeftContent && { right: "40%" }}
        />
      ) : (
        <AddingEmployeeOptions
          accountId={accountId}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          selectedSubOption={selectedSubOption}
          setSelectedSubOption={setSelectedSubOption}
          employeeSurveyPin={employeeSurveyPin}
          onClose={onClose}
          isSliderLeftContent={isSliderLeftContent}
        />
      )}
    </>
  );
};
export default AddEmployeesInputs;
