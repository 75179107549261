import confetti from "canvas-confetti";

import { triggerGoogleEvent } from "../analytics";

import { faCalendarExclamation } from "@fortawesome/pro-solid-svg-icons";
import {
  faDollarSign,
  faHashtag,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  formatDecimal,
  formatDollars,
  letterSBoolean,
} from "@aclymatepackages/formatters";

export const fireConfetti = () => {
  const fire = (particleRatio, opts) => {
    confetti({
      ...opts,
      particleCount: Math.floor(200 * particleRatio),
      origin: { y: 0.7, x: 0.3 },
      zIndex: 9999,
    });
  };

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
};

export const googlePurchaseTracking = (
  event,
  { project, purchaseDollars, purchaseTons },
  otherData
) => {
  const { slug, name, offsetProvider, typeSlug } = project || {};

  return triggerGoogleEvent(
    event,
    {
      item_id: slug,
      item_name: name,
      item_brand: offsetProvider,
      item_category: typeSlug,
      price: purchaseDollars,
      quantity: purchaseTons,
    },
    otherData
  );
};

export const generateDisclaimers = (additionalDisclaimers) => [
  ...additionalDisclaimers,
  {
    icon: faCalendarExclamation,
    text: "This purchase will not fully offset your carbon footprint and you will still have a negative carbon balance.",
  },
  {
    icon: faExclamation,
    text: "All offset purchases are non-refundable since your offsets will be retired immediately.",
  },
];

export const generateDisplayIcons = ({
  certificationLevel,
  purchaseDollars,
  purchaseTons,
  discountPrice,
  badge,
  badgeYears,
  badgeName,
}) => {
  const badgeDisplayIcon =
    certificationLevel || !badge
      ? []
      : [
          {
            icon: (
              <img
                alt="offsets badge"
                src={`/images/badges/svg/${badge}-offsets.svg`}
                style={{ width: "100px" }}
              />
            ),
            title: badgeName,
            subtitle: `You'll earn your ${badgeName} badge for ${badgeYears} year${letterSBoolean(
              badgeYears
            )}`,
          },
        ];

  const buildPriceTitle = () => {
    if (!discountPrice) {
      return formatDollars(purchaseDollars);
    }

    return (
      <>
        <span style={{ textDecoration: "line-through" }}>
          {formatDollars(purchaseDollars)}
        </span>
        {` ${formatDollars(discountPrice)}`}
      </>
    );
  };

  return [
    {
      icon: <FontAwesomeIcon icon={faDollarSign} size="3x" />,
      title: buildPriceTitle(),
      subtitle: "This is how much you're paying for your offsets.",
    },
    {
      icon: <FontAwesomeIcon icon={faHashtag} size="3x" />,
      title: `${formatDecimal(purchaseTons)}`,
      subtitle: "This is how many tonnes of offsets you're buying.",
    },
    ...badgeDisplayIcon,
  ];
};
