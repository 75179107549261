import React from "react";
import dayjs from "dayjs";

import {
  Box,
  Grid,
  Button,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Avatar,
  useTheme,
  Tooltip,
} from "@mui/material";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTasks } from "@fortawesome/free-solid-svg-icons";
import { faBooks } from "@fortawesome/pro-solid-svg-icons";

import { AnimatedLogo } from "@aclymatepackages/atoms";
import { formatDecimal, letterSBoolean } from "@aclymatepackages/formatters";
import { hexToRgba } from "@aclymatepackages/converters";

import Link from "../../atoms/mui/Link";
import Card from "../../atoms/mui/Card";
import ErrorBoundary from "../../atoms/ErrorBoundary";
import CloseAccountingButton from "../../atoms/accounting/CloseAccountingButton";
import AccountingChart from "../../modules/charts/AccountingChart";
import DashboardViewLayout from "../../layouts/DashboardViewLayout";

import useAccountingData from "../../../helpers/hooks/accountingData";
import { useCompletedOnboardingSteps } from "../../../helpers/hooks/companyData";

const NoAccountingDisplay = ({ cta, action }) => (
  <Box p={4}>
    <Grid container direction="column" spacing={2} alignItems="center">
      <Grid item>
        <img
          alt="accounting welcome"
          src="/images/platform/accounting-welcome.svg"
          width="300"
        />
      </Grid>
      <Grid item>
        <Typography variant="h5" align="center">
          {cta}
        </Typography>
      </Grid>
      <Grid item>{action}</Grid>
    </Grid>
  </Box>
);

const AccountingTableRow = ({
  year,
  tonsCo2e,
  baselinePercentage,
  pledgePercentage,
  status,
  badge,
}) => {
  const { palette } = useTheme();

  const displayPercentage = pledgePercentage || baselinePercentage;

  const OffsetBadge = ({ tooltip, badge }) => (
    <Tooltip title={tooltip}>
      <img
        alt=""
        style={{ width: "100px" }}
        src={`/images/badges/svg/${badge}-offsets.svg`}
      />
    </Tooltip>
  );

  const platinumBadge = {
    tooltip: "You've fully offset your scope 1+2 emissions this year",
    badge: "platinum",
  };
  const diamonBadge = {
    tooltip: "You've fully offsets your scope 3 emissions this year",
    badge: "diamond",
  };

  const buildBadgeDisplay = () => {
    if (!badge) {
      return <></>;
    }
    if (badge === "diamond") {
      return (
        <Grid container>
          {[platinumBadge, diamonBadge].map((badge, idx) => (
            <Grid item>
              <OffsetBadge key={`display-badge-${idx}`} {...badge} />
            </Grid>
          ))}
        </Grid>
      );
    }

    return <OffsetBadge {...platinumBadge} />;
  };

  const buildStatusDisplay = () => {
    if (status === "done") {
      return (
        <Grid spacing={2} container wrap="nowrap">
          <Grid item>
            <Typography variant="h6">CLOSED</Typography>
          </Grid>
          <Grid item>
            <FontAwesomeIcon
              icon={faCheck}
              size="1x"
              style={{ color: palette.secondary.main }}
            />
          </Grid>
        </Grid>
      );
    }

    return (
      <CloseAccountingButton
        type="accounting"
        disabled={status === "closed"}
        action={<CloseAccountingButton type="baseline" />}
      />
    );
  };

  const rowCells = [
    <Typography variant="h3">{year}</Typography>,
    <Typography variant="h6">
      {tonsCo2e ? formatDecimal(tonsCo2e) : "NA"}
    </Typography>,
    displayPercentage ? (
      <Grid container spacing={1} wrap="nowrap">
        <Grid item>
          <Typography variant="h6">
            {formatDecimal(displayPercentage)}%
          </Typography>
        </Grid>
        <Grid item>
          {displayPercentage > 100 ? (
            <ArrowDropUpIcon style={{ color: palette.error.main }} />
          ) : (
            <ArrowDropDownIcon style={{ color: palette.secondary.main }} />
          )}
        </Grid>
      </Grid>
    ) : (
      "NA"
    ),
    buildStatusDisplay(),
    buildBadgeDisplay(),
  ];

  return (
    <TableRow>
      {rowCells.map((cell, idx) => (
        <TableCell key={`table-cell-${idx}`}>{cell}</TableCell>
      ))}
    </TableRow>
  );
};

const AccountingDisplay = ({
  accountingStatus,
  unClosedYears = [],
  accountingYears,
  pledgeYear,
  pledgeYears,
  baselineDate,
  pledgeTons,
  baselineTonsCo2e,
}) => {
  const { palette } = useTheme();

  const buildTableDataArray = () => {
    const sortedAccountingYears = accountingYears.sort(
      (a, b) => a.year - b.year
    );
    const accountingYearsWithStatuses = sortedAccountingYears.map((year) => ({
      ...year,
      status: "done",
    }));

    if (!unClosedYears.length) {
      return accountingYearsWithStatuses;
    }

    const sortedUnClosedYears = unClosedYears.sort((a, b) => a.year - b.year);
    const unClosedYearsWithStatuses = sortedUnClosedYears.map(
      ({ date }, idx) => ({
        year: dayjs(date).year(),
        status: idx ? "closed" : "open",
      })
    );
    return [...accountingYearsWithStatuses, ...unClosedYearsWithStatuses];
  };

  const buildSubtitle = () => {
    if (accountingStatus === "no-baseline") {
      return "Follow the steps below to set your baseline and start your accounting";
    }

    if (accountingStatus === "closed") {
      return "Good job! You're accounting is fully up to date";
    }

    return `You need to close your acounting for ${
      unClosedYears.length
    } year${letterSBoolean(unClosedYears)}.`;
  };

  return (
    <Box
      p={2}
      style={{ backgroundColor: hexToRgba(palette.backgroundGray.main, 0.2) }}
    >
      <DashboardViewLayout
        title="Accounting"
        subtitle={buildSubtitle()}
        avatar={
          <Avatar style={{ backgroundColor: palette.primary.main }}>
            <FontAwesomeIcon icon={faBooks} />
          </Avatar>
        }
        graph={
          <Card
            style={{ overflow: "visible" }}
            cardType="layout"
            icon={<FontAwesomeIcon icon={faBooks} />}
            colorType="secondary"
            title="Closed Accounting Years"
            content={
              <AccountingChart
                accountingYears={accountingYears}
                pledgeYear={pledgeYear}
                pledgeYears={pledgeYears}
                baselineDate={baselineDate}
                pledgeTons={pledgeTons}
                baselineTonsCo2e={baselineTonsCo2e}
                card
              />
            }
          />
        }
        table={
          <Card
            cardType="layout"
            title="Closed Accounting Years"
            icon={<FontAwesomeIcon icon={faTasks} />}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  {[
                    "YEAR",
                    "TOTAL TONNES EMITTED",
                    "BASELINE/PLEDGE PERCENTAGE",
                    "STATUS",
                    "OFFSETS",
                  ].map((column, idx) => (
                    <TableCell key={`head-cell-${idx}`}>{column}</TableCell>
                  ))}
                </TableHead>
                <TableBody>
                  {buildTableDataArray().map((row, idx) => (
                    <AccountingTableRow key={`table-row-${idx}`} {...row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        }
      />
    </Box>
  );
};

const AccountingPage = () => {
  const [
    {
      status,
      unClosedYears = [],
      baselineDate,
      accountingYears,
      baselineTonsCo2e,
      pledgeYear,
      pledgeYears,
      pledgeTons,
    },
    accountingDataLoading,
  ] = useAccountingData();
  const [
    { officesComplete, vendorsComplete, employeesComplete },
    completedOnboardingStepsLoading,
  ] = useCompletedOnboardingSteps();

  if (accountingDataLoading || completedOnboardingStepsLoading) {
    return (
      <Box p={2} display="flex" justifyContent="center">
        <AnimatedLogo />
      </Box>
    );
  }

  if (baselineDate) {
    return (
      <AccountingDisplay
        accountingStatus={status}
        unClosedYears={unClosedYears}
        accountingYears={accountingYears}
        baselineTonsCo2e={baselineTonsCo2e}
        pledgeYear={pledgeYear}
        pledgeYears={pledgeYears}
        baselineDate={baselineDate}
        pledgeTons={pledgeTons}
      />
    );
  }

  if (
    !baselineDate &&
    officesComplete &&
    employeesComplete &&
    vendorsComplete
  ) {
    return (
      <NoAccountingDisplay
        cta="Start your climate accounting journey by setting a baseline for your account"
        action={<CloseAccountingButton type="baseline" />}
      />
    );
  }

  return (
    <NoAccountingDisplay
      cta="Finish setting up your account before you can start your climate accounting journey"
      action={
        <Link href="/platform/company/onboarding">
          <Button variant="contained" color="primary">
            Finish setting up your company
          </Button>
        </Link>
      }
    />
  );
};

const Accounting = () => (
  <ErrorBoundary>
    <AccountingPage />
  </ErrorBoundary>
);
export default Accounting;
