import React, { useState, useContext } from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  StyledEngineProvider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  IconButton,
  Divider,
  CircularProgress,
  useTheme,
  ThemeProvider,
} from "@mui/material";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import CheckIcon from "@mui/icons-material/Check";

import { AnimatedLogo } from "@aclymatepackages/atoms";

import { mergeDarkTheme } from "@aclymatepackages/themes";
import { formatDollars } from "@aclymatepackages/formatters";

import ProductDetailsLeftContent from "./ProductDetailsLeftContent";
import SubscriptionActionBlock from "../../modules/SubscriptionActionBlock";
import SlideLayout from "../../layouts/SlideLayout";
import PaymentInput from "../../modules/PaymentInput";

import { useCustomerCoupon } from "../../../helpers/hooks/stripe";
import { StripeCustomerContext } from "../../../helpers/contexts/stripeCustomer";

const PriceWithCoupon = ({ value, interval, subscriptionType }) => {
  const [subscriptionTypeCoupon, couponLoading] =
    useCustomerCoupon(subscriptionType);

  if (couponLoading) {
    return <CircularProgress />;
  }

  if (subscriptionTypeCoupon) {
    const { percentOff, amountOff = 0 } = subscriptionTypeCoupon || {};
    const adjustedValue = percentOff
      ? value * ((100 - percentOff) / 100)
      : value - amountOff;

    return (
      <>
        <Typography variant="h6" color="textPrimary" align="right">
          <span style={{ textDecoration: "line-through" }}>{`$${value}`}</span>
          {` ${formatDollars(adjustedValue, 0)}/${interval}`}
        </Typography>
        <Typography variant="caption" align="right" color="textSecondary">
          {`A discount has been applied to your account`}
        </Typography>
      </>
    );
  }

  return (
    <Typography variant="h6" color="textPrimary">{`${formatDollars(
      value,
      0
    )}/${interval}`}</Typography>
  );
};

const SubscriptionSignUpForm = ({
  subscriptionType,
  subtitle,
  title,
  prices,
  image,
  status,
  product,
  redirectUrl,
}) => {
  const { palette } = useTheme();

  const queryParams = new URLSearchParams(window.location.search);
  const selectedInterval = queryParams.get("interval");

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(
    selectedInterval || "year"
  );

  const { interval, stripePriceId, value } = prices.find(
    ({ interval }) => interval === "one-time" || interval === selectedPrice
  );

  const generateContent = () => {
    return (
      <Paper style={{ overflow: "hidden" }}>
        <Box p={2} style={{ backgroundColor: palette.primary.main }}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={mergeDarkTheme}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h6" color="textPrimary">
                    {title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <PriceWithCoupon
                        value={value}
                        interval={interval}
                        subscriptionType={subscriptionType}
                      />
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                      >
                        <ArrowDropDownCircleIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ThemeProvider>
          </StyledEngineProvider>
        </Box>
        <Box
          position="relative"
          style={{
            backgroundImage: `url('/images/platform/${image}.jpg')`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            paddingTop: "66%",
          }}
        >
          <Box
            position="absolute"
            style={{ inset: 0 }}
            display="flex"
            flexDirection="column"
          >
            <Box
              flexGrow={1}
              style={{
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.85) 100%)",
              }}
            />
            <Box
              p={2}
              flexGrow={0}
              style={{ backgroundColor: "rgba(255, 255, 255, 0.85)" }}
            >
              <Typography variant="subtitle1">{subtitle}</Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          <SubscriptionActionBlock
            title={title}
            status={status}
            subscriptionType={subscriptionType}
            stripeId={stripePriceId}
            interval={interval}
            value={value}
            product={product}
            redirectUrl={redirectUrl}
          />
        </Box>
      </Paper>
    );
  };

  return (
    <>
      {menuAnchorEl && (
        <Menu
          anchorEl={menuAnchorEl}
          open={!!menuAnchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <List>
            {prices.map(({ interval, value }, idx) => (
              <ListItem
                key={`price-select-menu-item-${idx}`}
                button
                onClick={() => {
                  setSelectedPrice(interval);
                  return setMenuAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  {interval === selectedPrice && <CheckIcon />}
                </ListItemIcon>
                <ListItemText>
                  {`${formatDollars(value, 0)}/${interval}`}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Menu>
      )}
      {generateContent()}
    </>
  );
};

const ProductSignUpForm = ({
  subtitle,
  prices,
  product,
  status,
  image,
  title,
  bullets,
  icon,
  onClose,
  redirectUrl,
}) => {
  const [subscriptionTypeCoupon, couponLoading] = useCustomerCoupon(product);
  const { paymentMethod, isPaymentMethodLoading } = useContext(
    StripeCustomerContext
  );

  const displayContent = () => {
    if (isPaymentMethodLoading || couponLoading) {
      return (
        <Grid container justifyContent="center">
          <Grid item>
            <AnimatedLogo />
          </Grid>
        </Grid>
      );
    }

    const { card, us_bank_account } = paymentMethod || {};
    const { percentOff } = subscriptionTypeCoupon || {};

    if (!(card || us_bank_account) && percentOff !== 100) {
      return (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h6">
              Please input your payment method
            </Typography>
          </Grid>
          <Grid item>
            <PaymentInput />
          </Grid>
        </Grid>
      );
    }

    return (
      <SubscriptionSignUpForm
        title={title}
        status={status}
        subscriptionType={product}
        subtitle={subtitle}
        prices={prices}
        image={image}
        product={product}
        redirectUrl={redirectUrl}
      />
    );
  };

  return (
    <SlideLayout
      isSlideOpen
      setIsSlideOpen={onClose}
      content={displayContent()}
      leftContent={
        <ProductDetailsLeftContent
          bullets={bullets}
          title={title}
          icon={icon}
        />
      }
    />
  );
};
export default ProductSignUpForm;
